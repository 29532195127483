import Navigation from "../Navigation";
import {
    GovBreadcrumbs, GovButton,
    GovFormControl, GovFormFile,
    GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon, GovMessage, GovSpacer, GovTile, GovTiles
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import "../css/car-park.css";
import {useNavigate} from "react-router-dom";

export function CarAddContent() {
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")

    const [carName, setCarName] = useState(null);
    const [carLicensePlate, setCarLicensePlate] = useState(null);
    const [carFuelType, setCarFuelType] = useState("");
    const [carConsumption, setCarConsumption] = useState(null);

    const [carNameValid, setCarNameValid] = useState(false);
    const [carLicensePlateValid, setCarLicensePlateValid] = useState(false);
    const [carFuelTypeValid, setCarFuelTypeValid] = useState(false);
    const [carConsumptionValid, setCarConsumptionValid] = useState(false);

    const [carValid, setCarValid] = useState(false);

    function checkErrors() {
        let ready = carNameValid && carLicensePlateValid && carFuelTypeValid && carConsumptionValid
        setCarValid(ready)
    }

    useEffect(() => {
        checkErrors()
    }, [carNameValid,carLicensePlateValid,carFuelTypeValid,carConsumptionValid]);

    const handleCarNameChange = (event) => {
        if (event.target.value !== "") {
            setCarNameValid(true);
        } else {
            setCarNameValid(false);
        }
        setCarName(event.target.value);
    };

    const handleCarIdChange = (event) => {
        if (event.target.value !== "") {
            setCarLicensePlateValid(true);
        } else {
            setCarLicensePlateValid(false);
        }
        setCarLicensePlate(event.target.value);
    };

    const handleCarFuelTypeChange = (event) => {
        if (event.target.value !== "") {
            setCarFuelTypeValid(true);
        } else {
            setCarFuelTypeValid(false);
        }
        setCarFuelType(event.target.value);
    };

    const handleCarConsumptionChange = (event) => {
        if (isNumeric(event.target.value) && parseFloat(event.target.value) > 0) {
            setCarConsumptionValid(true);
        } else {
            setCarConsumptionValid(false);
        }
        setCarConsumption(event.target.value);
    };

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }
    const navigate = useNavigate()
    const uploadDocument = (a,b) => {}
    const jwt = localStorage.getItem("jwt");
    function postCar() {
        fetch(process.env.REACT_APP_API_URL + "/vehicles/private", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
        {
                "name": carName,
                "licensePlate": carLicensePlate,
                "fuelTypeId": carFuelType,
                "consumption": carConsumption
            }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                navigate("/profile/cars")
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    return (
        <div>
            <Navigation />
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/settings">Nastavení</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/profile/cars">Správa vlastního vozového parku</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Přidat vozidlo</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">
                    <div className="gov-title--beta">{carName}</div>
                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder car-add">
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    <form method="post">
                                        <GovFormControl>
                                            <GovFormInput value={carName}
                                                          onGov-input={handleCarNameChange}
                                                          size="s" variant="secondary"
                                                          input-type="text"
                                                          placeholder="Název vozidla"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Název vozidla*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput value={carLicensePlate}
                                                          onGov-input={handleCarIdChange}
                                                          size="s" variant="secondary"
                                                          input-type="text"
                                                          placeholder="SPZ"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">SPZ*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormSelect size="s" variant="secondary" value={carFuelType}
                                                           onGov-change={handleCarFuelTypeChange}>
                                                <option disabled="disabled" value="">Typ paliva</option>
                                                <option value="3">Nafta</option>
                                                <option value="1">Natural 95</option>
                                                <option value="2">Natural 98</option>
                                                <option value="4">Elektrický pohon</option>
                                            </GovFormSelect>
                                            <GovFormLabel slot="top" size="s">Typ paliva*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput value={carConsumption}
                                                          onGov-input={handleCarConsumptionChange}
                                                          size="s" variant="secondary"
                                                          input-type="number" placeholder="Kombinovaná spotřeba dle technického průkazu"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Kombinovaná spotřeba dle technického průkazu*</GovFormLabel>
                                        </GovFormControl>
                                        {!carValid &&
                                            <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné položky</p>
                                            </GovMessage>}
                                        <GovButton className={"send-button"} onGov-click={postCar}
                                                   wcag-label="What exactly happens when you click?"
                                                   variant="primary" disabled={!carValid} type="solid" size="m">Uložit
                                        </GovButton>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
