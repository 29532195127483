import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {VillageDetailContent} from "../components/contentComponents/VillageDetailContent";
import {useParams} from "react-router-dom";
import {TownCollectiveDetailContent} from "../components/contentComponents/TownCollectiveDetailContent";

export function TownCollectiveDetail() {
    const { id } = useParams();
    return (
        <div>
            <Header></Header>
            <TownCollectiveDetailContent  townId={id}></TownCollectiveDetailContent>
            <Footer></Footer>
        </div>
    )
}
