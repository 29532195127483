import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {UsersCarsPageContent} from "../components/contentComponents/UsersCarsPageContent";

export function UsersCarsPage() {
    return (
        <div>
            <Header active={"profile-active"}></Header>
            <UsersCarsPageContent></UsersCarsPageContent>
            <Footer></Footer>
        </div>
    )
}