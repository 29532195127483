import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {DashboardContent} from "../components/contentComponents/DashboardContent";
import {DashboardPageContent} from "../components/contentComponents/DashboardPageContent";

export function Dashboard() {
    return (
        <div>
            <Header active={"dashboard-active"}></Header>
            <DashboardPageContent></DashboardPageContent>
            <Footer></Footer>
        </div>
    )
}
