import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {ProjectsContent} from "../components/contentComponents/ProjectsContent";

export function Projects() {
    let user = {
        role: 'RC_LEADER'
    }
    return (
        <div>
            <Header></Header>
            <ProjectsContent user={user}></ProjectsContent>
            <Footer></Footer>
        </div>
    )
}
