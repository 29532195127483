import {HomePage} from "../homepage/HomePage";
import {UserHomePage} from "../homepage/UserHomePage";

export function HomeContent({ user }) {
    let role = null
    if(user != null){
        role = user.role
    }


    switch (role) {
        case "HEADQUARTERS":
            return <UserHomePage />;
        case "RC_LEADER":
            return <UserHomePage />;
        case "RC_EMPLOYEE":
            return <UserHomePage />;
        default:
            return <HomePage />;
    }
}
