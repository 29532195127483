import {
    GovButton,
    GovFormControl,
    GovFormFile,
    GovFormInput,
    GovFormLabel, GovIcon, GovMessage,
    GovSpacer, GovToast
} from "@gov-design-system-ce/react";
import {useRef, useState} from "react";
import {parseJwt} from "../utils/utils";
import {useNavigate} from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'

export function MMRExportContent() {
    const jwt = localStorage.getItem("jwt");
    const [requestSuccess, setRequestSuccess] = useState(false);
    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    const navigate = useNavigate()
    if (user !== null) {
        role = user.role[0].authority
    }

    function goToLogin() {
        navigate("/login")
    }


    const handleSubmit = (event) => {
        fetch(process.env.REACT_APP_API_URL + "/report/mmr/request", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
        })
            .then(data => {
                    console.log(data)
                    setRequestSuccess(true)
                }
            )
            .catch(error => console.error('Error:', error));
    }

    const [recaptchaValue, setRecaptchaValue] = useState(null);
    const handleRecaptchaChange = (value) => {
        setRecaptchaValue(value);
    }

    return (

        <div>
            {role === "HEADQUARTERS" ?
                <div>
                    <div className="gov-container">
                        <div className="gov-container__content vh-80">
                            <form>
                                <ReCAPTCHA hl="cs" sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleRecaptchaChange}/>
                                {!recaptchaValue &&
                                    <GovMessage className="mt-10" variant="error">
                                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                        <p>reCAPTCHA není splněna</p>
                                    </GovMessage>
                                }
                                <GovButton onGov-click={handleSubmit} wcag-label="What exactly happens when you click?"
                                           variant="primary" type="solid"
                                           disabled={!recaptchaValue}
                                           class="mt-10"
                                           size="m">Vyžádat export dat
                                </GovButton>
                            </form>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="gov-container">
                        <div className="gov-container__content vh-80">
                            <h3>Pro tuto operaci nemáte dostatečná oprávnění.</h3>
                            <GovButton onGov-click={goToLogin}
                                       wcag-label="What exactly happens when you click?"
                                       variant="primary" type="solid"
                                       size="m">Přejít na přihlašovací stránku
                            </GovButton>
                        </div>
                    </div>
                </div>
            }
            <div>
                {requestSuccess &&
                    <GovToast variant="success" type="classic" gravity="bottom" position="center" time="10000"
                              wcag-close-label="Close message">Žádost o export dat byla úspěšně provedena.
                        Soubor s exportem dat Vám bude zaslán emailem.
                        <GovIcon type="basic" name="check-lg" slot="icon"></GovIcon>
                    </GovToast>

                }
            </div>
        </div>

    )

}
