import React from 'react';
import {
    BarChart,
    Bar,
    Rectangle,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ComposedChart, Line, Area, Label
} from 'recharts';

export default function HorizontalBarChartCustomForPhases({data, title, width, height}) {
    return (
        <div className="no-bullets">
            <h3>{title}</h3>
            <ComposedChart
                width={width}
                height={height}
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
            >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" scale="point" padding={{ left: 25, right: 25 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Počet" barSize={20} fill="#413ea0" />
                <Line type="monotone" dataKey="Vize" stroke="#0088FE" />
                <Line type="monotone" dataKey="Předprojektová příprava" stroke="#00C49F" />
                <Line type="monotone" dataKey="Projektová fáze" stroke="#FFBB28" />
                <Line type="monotone" dataKey="Stavební povolení" stroke="#FF8042" />
                <Line type="monotone" dataKey="Realizace" stroke="#d14760" />
            </ComposedChart>
        </div>
    );
}
