import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {SearchVillageContent} from "../components/contentComponents/SearchVillageContent";

export function VillageSearch() {
    return (
        <div>
            <Header></Header>
            <SearchVillageContent></SearchVillageContent>
            <Footer></Footer>
        </div>
    )
}