import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {SFPICarsPageContent} from "../components/contentComponents/SFPICarsPageContent";

export function SFPICarsPage() {
    return (
        <div>
            <Header active={"settings-active"}></Header>
            <SFPICarsPageContent></SFPICarsPageContent>
            <Footer></Footer>
        </div>
    )
}
