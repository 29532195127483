import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {NewConsultationContent} from "../components/contentComponents/NewConsultationContent";

export function NewConsultation() {
    return (
        <div>
            <Header></Header>
            <NewConsultationContent></NewConsultationContent>
            <Footer></Footer>
        </div>
    )
}
