import {Header} from "../components/Header";
import {SettingNewPasswordContent} from "../components/contentComponents/SettingNewPasswordContent";
import {Footer} from "../components/Footer";
import {SyncConsultationsContent} from "../components/contentComponents/SyncConsulatationsContent";

export function SyncConsultations() {
    return (
        <div>
            <Header></Header>
            <SyncConsultationsContent></SyncConsultationsContent>
            <Footer></Footer>
        </div>
    )
}
