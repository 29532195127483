import {
    GovButton,
    GovFormCheckbox,
    GovFormControl, GovFormFile, GovFormGroup,
    GovFormInput,
    GovFormLabel, GovFormRadio, GovFormSelect,
    GovMessage,
    GovModal, GovTabs, GovTabsItem
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {
    convertIsoToDateFormat,
    convertIsoToLocalDate,
    convertIsoToTime,
    getIcon, mapTimeSheetType, mapTransportType,
    typeToText,
    userRoleToText
} from "../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function TripReportDetailModal(props) {
    const jwt = localStorage.getItem("jwt");

    const [councilHousingUnitsCount, setCouncilHousingUnitsCount] = useState();
    const [marketRent, setMarketRent] = useState();
    const [councilRent, setCouncilRent] = useState();

    const [councilHousingUnitsCountInvalid, setCouncilHousingUnitsCountInvalid] = useState(false);
    const [marketRentInvalid, setMarketRentInvalid] = useState(false);
    const [councilRentInvalid, setCouncilRentInvalid] = useState(false);
    const [aszCooperation, setAszCooperation] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const [dateOfContact, setDateOfContact] = useState();
    const [townContactEventMethodId, setTownContactEventMethodId] = useState('');
    const [contactedByUserId, setContactedByUserId] = useState('');
    const [townInterested, setTownInterested] = useState();
    const [housingFundRating, setHousingFundRating] = useState('');

    const [typeOfInputInformation, setTypeOfInputInformation] = useState("Cesta");


    const [rcPeople, setRcPeople] = useState([]);
    const [modalOpen, setModalOpen] = useState(true);

    const fetchRcPeople = () => {
        fetch(process.env.REACT_APP_API_URL + "/rc/" + props.town.regionalCenter.id + "/employees", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setRcPeople(jsonData)
                console.log(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    function handleCouncilHousingUnitsCountChange(event) {
        if (event.target.value < 0) {
            setCouncilHousingUnitsCountInvalid(true);
        } else {
            setCouncilHousingUnitsCountInvalid(false);
        }
        setCouncilHousingUnitsCount(event.target.value);
    }

    function handleMarketRentChange(event) {
        if (event.target.value < 0) {
            setMarketRentInvalid(true);
        } else {
            setMarketRentInvalid(false);
        }
        setMarketRent(event.target.value);
    }

    function handleCouncilRentChange(event) {

        if (event.target.value < 0) {
            setCouncilRentInvalid(true);
        } else {
            setCouncilRentInvalid(false);
        }
        setCouncilRent(event.target.value);
    }

    const handleAszCooperationChange = (event) => {
        setAszCooperation(!aszCooperation);
    };

    const handleHousingFundRatingChange = (event) => {
        setHousingFundRating(event.target.value);
    };

    const handleDateOfContactChange = (event) => {
        setDateOfContact(event.target.value);
    }

    const handleTownContactEventMethodIdChange = (event) => {
        setTownContactEventMethodId(event.target.value);
    }


    const handleContactUserIdChange = (event) => {
        setContactedByUserId(event.target.value);
    }

    const handleTownInterestedChange = (event) => {
        setTownInterested(!townInterested);
    }

    const handleTypeOfInputInformationChange = (event) => {
        setTypeOfInputInformation(event.detail.label)
    }

    function checkIfFormIsValid() {
        if (councilHousingUnitsCountInvalid || marketRentInvalid || councilRentInvalid) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }

    function updateTownDetail() {
        setModalOpen(false)
        fetch(process.env.REACT_APP_API_URL + "/towns/" + props.town.id + "/extended-info", {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "marketRent": marketRent,
                "councilRent": councilRent,
                "councilHousingUnitsCount": councilHousingUnitsCount,
                "housingFundRating": housingFundRating === "" ? null : housingFundRating,
                "aszCooperation": aszCooperation
            })
        })
            .then(response => response.json())
            .then(data => {
                props.onGovModalClose();
            });
    }

    function updateTownContact() {
        setModalOpen(false)
        const collective = props.town.townCollectiveContactEvent !== undefined ? "collective/" : ""
        const url = process.env.REACT_APP_API_URL + "/towns/" + props.town.id + "/contact-event"
        const method = ((props.town.townContactEvent !== null && props.town.townContactEvent !== undefined) || (props.town.townCollectiveContactEvent !== null && props.town.townCollectiveContactEvent !== undefined)) ? 'PUT' : 'POST'
        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "employeeId": contactedByUserId,
                "dateOf": dateOfContact,
                "townContactEventMethodId": townContactEventMethodId,
                "interested": townInterested
            })
        })
            .then(response => response.json())
            .then(data => {
                props.onGovModalClose();
            });
    }

    useEffect(() => {
        checkIfFormIsValid()
    }, [councilHousingUnitsCountInvalid, marketRentInvalid, councilRentInvalid]);

    useEffect(() => {
        if (props.town) {
            if (props.town.towns === null) {
                setTypeOfInputInformation("Kontakt obce")
            }
            if (props.town.councilHousingUnitsCount !== null) {
                setCouncilHousingUnitsCount(props.town.councilHousingUnitsCount)
                setCouncilHousingUnitsCountInvalid(false)
            }
            if (props.town.marketRent !== null) {
                setMarketRent(props.town.marketRent)
                setMarketRentInvalid(false)
            }
            if (props.town.councilRent !== null) {
                setCouncilRent(props.town.councilRent)
                setCouncilRentInvalid(false)
            }
            if (props.town.housingFundRating !== null) {
                setHousingFundRating(props.town.housingFundRating)
            }
            if (props.town.aszCooperation !== null) {
                setAszCooperation(props.town.aszCooperation)
            }
            if (props.town.townContactEvent !== null && props.town.townContactEvent !== undefined) {
                if (props.town.townContactEvent.date !== null) {
                    setDateOfContact(props.town.townContactEvent.date)
                }

                if (props.town.townContactEvent.employeeId !== null) {
                    setContactedByUserId(props.town.townContactEvent.employeeId)
                }

                if (props.town.townContactEvent.townContactEventMethodId !== null) {
                    setTownContactEventMethodId(props.town.townContactEvent.townContactEventMethodId)
                }

                if (props.town.townContactEvent.interested !== null) {
                    setTownInterested(props.town.townContactEvent.interested)
                }
            }
            if (props.town.townCollectiveContactEvent !== null && props.town.townCollectiveContactEvent !== undefined) {
                if (props.town.townCollectiveContactEvent.date !== null) {
                    setDateOfContact(props.town.townCollectiveContactEvent.date)
                }

                if (props.town.townCollectiveContactEvent.employeeId !== null) {
                    setContactedByUserId(props.town.townCollectiveContactEvent.employeeId)
                }

                if (props.town.townCollectiveContactEvent.townContactEventMethodId !== null) {
                    setTownContactEventMethodId(props.town.townCollectiveContactEvent.townContactEventMethodId)
                }

                if (props.town.townCollectiveContactEvent.interested !== null) {
                    setTownInterested(props.town.townCollectiveContactEvent.interested)
                }
            }


        }
    }, [props.town])


    function fetchExpenseDocument(id, name) {
        fetch(`${process.env.REACT_APP_API_URL}/workTrips/${props.roadTripId}/expenses/${id}/invoice/download`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }

    function calculateRoadTripDuration(startTime, endTime,) {
        const start = new Date(`${startTime}`);
        const end = new Date(`${endTime}`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            return 0
        } else {
            const decimalPart = result % 1;
            const validEndings = [0.00, 0.25, 0.50, 0.75];

            if (!validEndings.includes(decimalPart)) {
                return result.toFixed(2);
            } else {
                return result.toFixed(2);
            }
        }
    }

    const [roadTrip, setRoadTrip] = useState();

    function fetchCurrentWorkTripCompensation(id) {
        fetch(process.env.REACT_APP_API_URL + `/workTrips/${id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            setRoadTrip(jsonData)

        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    console.log(props)
    useEffect(() => {
        fetchCurrentWorkTripCompensation(props.roadTripId)
    }, [])

    return (
        <GovModal open={modalOpen} onGov-close={props.onGovModalClose}
                  label="Detail cestovních náhrad"
                  wcag-close-label="Close dialog box with more information">

            <GovTabs onGov-change={handleTypeOfInputInformationChange} type="chip" variant="primary"
                     wcag-label="Basic information about the gov.cz design system">
                <GovTabsItem label="Cesta"></GovTabsItem>
                <GovTabsItem label="Další náklady"/>
            </GovTabs>
            <div>
                {(typeOfInputInformation === "Cesta" && roadTrip) &&
                    <div>
                        <div className="gov-tabs__content is-active" role="tabpanel">
                            <div className="project-detail">
                                <div>
                                    <h4>Dopravní prostředek</h4>
                                    <p>{mapTransportType(roadTrip.transportType)}</p>
                                </div>
                                <div>
                                    <h4>Vozidlo</h4>
                                    <p>{roadTrip.transportType === 2 ? roadTrip.privateVehicle.name : roadTrip.transportType === 3 ? roadTrip.companyVehicle.name : "Dopravní prostředek není vozidlo"}</p>
                                </div>
                                <div>
                                    <h4>Upřesňující typ cesty</h4>
                                    <p>{mapTimeSheetType(roadTrip.workReport.type)}</p>
                                </div>
                                {roadTrip.workReport.town && roadTrip.workReport.type === 2 && (
                                    <div>
                                        <h4>Obec</h4>
                                        <p><a
                                            href={`/towns/detail/${roadTrip.workReport.town.id}`}>{roadTrip.workReport.town.name + " (" + roadTrip.workReport.town.id + ")"}</a>
                                        </p>
                                    </div>
                                )}
                                {roadTrip.workReport.project && roadTrip.workReport.type === 3 && (
                                    <div>
                                        <h4>Projekt</h4>
                                        <p><a
                                            href={`/projects/detail/${roadTrip.workReport.project.id}`}>{roadTrip.workReport.project.id}</a>
                                        </p>
                                    </div>
                                )}
                                {roadTrip.workReport.preProjectConsultationId && roadTrip.workReport.type === 7 && (
                                    <div>
                                        <h4>Konzultace</h4>
                                        <p><a
                                            href={`/new-consultation/detail/${roadTrip.workReport.preProjectConsultationId}`}>{roadTrip.workReport.preProjectConsultationId}</a>
                                        </p>
                                    </div>
                                )}
                                <div>
                                    <h4>Odkud</h4>
                                    <p>{roadTrip.from}</p>
                                </div>
                                <div>
                                    <h4>Kam</h4>
                                    <p>{roadTrip.to}</p>
                                </div>
                                <div>
                                    <h4>Délka cesty v km</h4>
                                    <p>{roadTrip.distance}</p>
                                </div>
                                <div>
                                    <h4>Datum cesty</h4>
                                    <p>{convertIsoToDateFormat(roadTrip.workReport.start)}</p>
                                </div>
                                <div>
                                    <h4>Začátek cesty</h4>
                                    <p>{convertIsoToTime(roadTrip.workReport.start)}</p>
                                </div>
                                <div>
                                    <h4>Konec cesty</h4>
                                    <p>{convertIsoToTime(roadTrip.workReport.end)}</p>
                                </div>
                                <div>
                                    <h4>Počet hodin</h4>
                                    <p>{calculateRoadTripDuration(roadTrip.workReport.start, roadTrip.workReport.end)}</p>
                                </div>
                                <div>
                                    <h4>Počet poskytnutých jídel</h4>
                                    <p>{roadTrip.mealsCount}</p>
                                </div>

                            </div>
                            <div>
                                <h4>Důvod/program cesty</h4>
                                <p>{roadTrip.description}</p>
                            </div>
                        </div>
                    </div>
                }

                {
                    (typeOfInputInformation === "Další náklady") &&
                    <div>
                        {(roadTrip.expenses.length !== 0 && roadTrip) ?
                            <table>
                                <thead>
                                <tr>
                                    <th>Název</th>
                                    <th>Cena vč. DPH</th>
                                    <th>Doklad o zaplacení</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {roadTrip.expenses.map((expense, index) => (
                                    <tr key={index}>
                                        <td>{expense.name}</td>
                                        <td>{expense.cost} Kč</td>
                                        <td>{expense.invoice && <div>
                                            <FontAwesomeIcon icon={getIcon(expense.invoice.name)}/>
                                            <span> </span>
                                            <a href={expense.invoice.link} onClick={(event) => {
                                                event.preventDefault();
                                                fetchExpenseDocument(expense.id, expense.invoice.name);
                                            }}>
                                                {expense.invoice.name}
                                            </a>
                                        </div>}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                            : (
                                <p>Žádné další náklady</p>
                            )
                        }
                    </div>
                }
            </div>

        </GovModal>
    )
}
