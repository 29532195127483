import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {SettingNewPasswordContent} from "../components/contentComponents/SettingNewPasswordContent";

export function SettingNewPassword() {
    return (
        <div>
            <Header></Header>
            <SettingNewPasswordContent></SettingNewPasswordContent>
            <Footer></Footer>
        </div>
    )
}