import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {EditProjectContent} from "../components/contentComponents/EditProjectContent";

export function EditProject() {
    return (
        <div>
            <Header></Header>
            <EditProjectContent></EditProjectContent>
            <Footer></Footer>
        </div>
    )
}
