import {DashboardContent} from "./DashboardContent";
import {GovBreadcrumbs, GovIcon, GovSpacer, GovTabs, GovTabsItem} from "@gov-design-system-ce/react";
import {useState} from "react";
import Navigation from "../Navigation";
import {DashboardKPI} from "./DashboardKPI";

export function DashboardPageContent() {

    const [dashboardType, setTypeOfDashboard] = useState("Dashboard");

    const handleTypeOfDashboardChange = (event) => {
        setTypeOfDashboard(event.detail.label)
    }

    return (
        <div>
            <Navigation/>
            <div className="gov-container dashboard-container">
                <div className="gov-container__content">
                    <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                        <ul>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/">Úvod</a></li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <strong>Dashboard</strong>
                            </li>
                        </ul>
                    </GovBreadcrumbs>
                </div>
                <GovSpacer size="l"></GovSpacer>
                <GovTabs className="gov-container__content" onGov-change={handleTypeOfDashboardChange} type="chip"
                         variant="primary"
                         wcag-label="Basic information about the gov.cz design system">
                    <GovTabsItem label="Dashboard"></GovTabsItem>
                    <GovTabsItem label="KPI"></GovTabsItem>
                </GovTabs>
                <div className={dashboardType === "Dashboard" ? "visible" : "hidden"}>
                    <DashboardContent/>
                </div>
                <div className={dashboardType === "KPI" ? "visible" : "hidden"}>
                    <DashboardKPI/>
                </div>
            </div>
        </div>
    )
}