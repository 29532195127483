import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {PasswordResetContent} from "../components/contentComponents/PasswordResetContent";

export function PasswordReset() {
    return (
        <div>
            <Header></Header>
            <PasswordResetContent></PasswordResetContent>
            <Footer></Footer>
        </div>
    )
}