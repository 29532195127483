import React, { useCallback, useState } from "react";
import {PieChart, Pie, Sector, Cell} from "recharts";

function roundToTwoDecimalPlaces(num) {
    // Zaokrouhlení čísla na dvě desetinná místa
    const roundedNum = Math.round(num * 100) / 100;

    // Pokud je zaokrouhlené číslo celé číslo, vrátí se jako `integer`
    return Number.isInteger(roundedNum) ? roundedNum : parseFloat(roundedNum.toFixed(2));
}

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
        dy
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
        <g>
            <text x={cx} y={cy} dy={dy} textAnchor="middle" fill={fill}>
                {payload.name}
            </text>
            <text x={cx} y={cy} dy={-10} textAnchor="middle" fill={fill}>
                Celkem
            </text>
            <text x={cx} y={cy} dy={10} textAnchor="middle" fill={fill}>
                {roundToTwoDecimalPlaces(payload.payload.total)}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill="#333"
            >{`${roundToTwoDecimalPlaces(value)}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`(${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

export default function PieChartCustomPercent({
                                                  title,
                                                  data,
                                                  width,
                                                  height,
                                                  dy = 8,
                                                  color = "#8884d8",
                                                  innerRadius=70, outerRadius=85, startAngle=0, endAngle=360, cx=210, cy=120}) {
    const [activeIndex, setActiveIndex] = useState(0);
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#d14760', '#d16dca'];
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    return (
        <div className="w-370px">
            <h3>{title}</h3>
            <PieChart width={width} height={height}>
                <Pie
                    dy={dy}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={data}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    fill={color}
                    dataKey="value"
                    onMouseEnter={onPieEnter}
                    paddingAngle={5}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </div>
    );
}
