import {
    GovBreadcrumbs,
    GovButton, GovFormCheckbox,
    GovFormControl,
    GovFormGroup,
    GovFormInput, GovFormLabel, GovFormMessage,
    GovFormPasswordPower, GovIcon,
    GovSpacer
} from "@gov-design-system-ce/react";
import Navigation from "../Navigation";
import '../css/login.css';
import {useState} from "react";
export function FirstLoginContent() {
    const user = {
        role: "RC_LEADER" // or false based on the user's rights
        // Add other user attributes as needed
    };

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [paswordCheck, setPasswordCheck] = useState('')

    const [emailInvalid, setEmailInvalid] = useState(false)
    const [passwordInvalid, setPasswordInvalid] = useState(false)
    const [passwordSameInvalid, setPasswordSameInvalid] = useState(false)

    const [checkboxChecked, setCheckboxChecked] = useState(false)

    function sendForm() {
        let readyToSend = checkErrors()
        if (readyToSend) {
            console.log("SEND")
        }
    }

    function isValidEmail(email) {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    function isStrongPassword(password) {
        const minLength = 8;
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*()\-_=+{};:,<.>]/.test(password);

        return (
            password.length >= minLength &&
            hasUpperCase &&
            hasLowerCase &&
            hasNumber &&
            hasSpecialChar
        );
    }

    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }
    const handleChangePassword = (event) => {
        setPassword(event.target.value)
    }
    const handleChangePasswordCheck = (event) => {
        setPasswordCheck(event.target.value)
    }

    function checkErrors() {
        let ready = true
        if (!isValidEmail(email)) {
            ready = false
            setEmailInvalid(true)
        } else {
            setEmailInvalid(false)
        }
        if (!isStrongPassword(password)) {
            ready = false
            setPasswordInvalid(true)
        } else {
            setPasswordInvalid(false)
        }
        if (password !== paswordCheck) {
            ready = false
            setPasswordSameInvalid(true)
        } else {
            setPasswordSameInvalid(false)
        }
        if (!checkboxChecked) {
            ready = false
        }
        return ready
    }


    return (
        <div>
            <Navigation active={"home"} />
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>První přihlášení</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">První přihlášení</div>
                            <form className="gov-form">
                                <GovFormControl>
                                    <GovFormGroup>
                                        <GovSpacer size="m"></GovSpacer>
                                        <GovFormControl>
                                            <GovFormInput invalid={emailInvalid} onGov-input={handleChangeEmail} value={email}
                                                          size="s" variant="secondary" input-type="email"
                                                          placeholder="Email"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Email*</GovFormLabel>
                                            <div>
                                            {emailInvalid && (
                                                    <GovFormMessage slot="bottom" variant="error">Email není ve správném
                                                        formátu
                                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                                 slot="icon"></GovIcon>
                                                    </GovFormMessage>
                                                )}
                                            </div>
                                                </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput invalid={passwordInvalid} onGov-input={handleChangePassword}
                                                          value={password} size="s" variant="secondary"
                                                          input-type="password" placeholder="Heslo"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Heslo*</GovFormLabel>
                                            <div>
                                                {passwordInvalid && (
                                                    <GovFormMessage slot="bottom" variant="error">Heslo nesplňuje
                                                        podmínky
                                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                                 slot="icon"></GovIcon>
                                                    </GovFormMessage>)}
                                            </div>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput invalid={passwordSameInvalid}
                                                          onGov-input={handleChangePasswordCheck} value={paswordCheck}
                                                          size="s" variant="secondary" input-type="password"
                                                          placeholder="Potvrzení hesla"></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Potvrzení hesla*</GovFormLabel>
                                            <div>
                                                {passwordSameInvalid &&

                                                    <GovFormMessage slot="bottom" variant="error">Hesla se neshodují
                                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                                 slot="icon"></GovIcon>
                                                    </GovFormMessage>

                                                }
                                            </div>
                                        </GovFormControl>
                                    </GovFormGroup>
                                </GovFormControl>
                                <GovFormControl>
                                    <GovFormCheckbox checked={checkboxChecked} size="s" value="confirmed">
                                        <GovFormLabel size="s" slot="label">Potvrzuji, že jsem se seznámil s informacemi
                                            o
                                            zpracování osobních údajů, které jsou uvedeny v dokumentu umístěném na
                                            odkazu <a
                                                href="/zde">zde</a></GovFormLabel>
                                    </GovFormCheckbox>
                                    <div>
                                        {!checkboxChecked &&

                                            <GovFormMessage slot="bottom" variant="error">
                                                Pro registraci musí být zaškrtnuto
                                                <GovIcon type="basic" name="exclamation-triangle-fill" slot="icon"/>
                                            </GovFormMessage>
                                        }
                                    </div>
                                </GovFormControl>
                                <GovButton onGov-click={sendForm} wcag-label="What exactly happens when you click?"
                                           variant="primary" type="solid" size="m">Odeslat
                                </GovButton>
                            </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
