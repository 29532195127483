import Navigation from "../Navigation";
import {
    GovBreadcrumbs, GovButton, GovFormAutocomplete,
    GovFormControl,
    GovFormInput,
    GovFormLabel, GovFormMultiSelect, GovFormSelect,
    GovIcon, GovMessage, GovModal, GovPagination, GovSkeleton,
    GovSpacer, GovTabs, GovTabsItem
} from "@gov-design-system-ce/react";
import '../css/search-village.css';
import {useEffect, useState} from "react";
import {convertDateToDDMMYYYY, convertIsoToCustomFormat, parseJwt} from "../utils/utils";


export function SearchVillageContent() {
    const allRegions = [
        "Hlavní město Praha",
        "Středočeský kraj",
        "Jihočeský kraj",
        "Plzeňský kraj",
        "Karlovarský kraj",
        "Ústecký kraj",
        "Liberecký kraj",
        "Královehradecký kraj",
        "Pardubický kraj",
        "Kraj Vysočina",
        "Jihomoravský kraj",
        "Olomoucký kraj",
        "Moravskoslezský kraj",
        "Zlínský kraj"
    ];
    const allRegionalCenters = [
        "Moravskoslezský kraj",
        "Středočeský kraj a Praha",
        "Ústecký a Karlovarský kraj",
        "Královehradecký a Liberecký kraj",
        "Pardubický kraj a kraj Vysočina",
        "Jihočeský kraj a Plzeňský kraj",
        "Zlínský a Jihomoravský kraj",
        "Olomoucký kraj"
    ];
    const regionalCentre = localStorage.getItem("RCname");
    const jwt = localStorage.getItem("jwt")
    const [towns, setTowns] = useState(null);
    const [townsForSelect, setTownsForSelect] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handlePageChange = (event) => {
        setCurrentPage(event.detail.pagination.currentPage);
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [letterChosen, setLetterChosen] = useState(false);
    const itemsPerPage = 20;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    let request;
    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");

    function getRegionalCenterByRegionName(regionName) {
        if (regionName === "Hlavní město Praha") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Moravskoslezský kraj") {
            return "Moravskoslezský kraj"
        } else if (regionName === "Olomoucký kraj") {
            return "Olomoucký kraj"
        } else if (regionName === "Pardubický kraj") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Kraj Vysočina") {
            return "Pardubický kraj a kraj Vysočina"
        } else if (regionName === "Zlínský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Královéhradecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Ústecký kraj") {
            return "Ústecký a Karlovarský kraj"
        } else if (regionName === "Plzeňský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Liberecký kraj") {
            return "Královehradecký a Liberecký kraj"
        } else if (regionName === "Jihomoravský kraj") {
            return "Zlínský a Jihomoravský kraj"
        } else if (regionName === "Jihočeský kraj") {
            return "Jihočeský kraj a Plzeňský kraj"
        } else if (regionName === "Středočeský kraj") {
            return "Středočeský kraj a Praha"
        } else if (regionName === "Karlovarský kraj") {
            return "Ústecký a Karlovarský kraj"
        }
    }

    const [selectedRegions, setSelectedRegions] = useState(allRegions);
    const [selectedRegionalCenters, setSelectedRegionalCenters] = useState(allRegionalCenters);
    const handleRegionChange = (event) => {
        setSelectedRegions(event.detail.value.map(region => region.name));
        let regionsToFilter = event.detail.value.map(region => region.name);
        if (regionsToFilter.length === 0) {
            regionsToFilter = allRegions
            setSelectedRegions(allRegions)
        }
        const filtered = towns.filter(town =>
            town.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            regionsToFilter.includes(town.region.name) &&
            selectedRegionalCenters.includes(getRegionalCenterByRegionName(town.region.name))
        );
        setFilteredItems(filtered);
    };

    const handleRegionalCenterChange = (event) => {
        setSelectedRegionalCenters(event.detail.value.map(regionalCenter => regionalCenter.name));
        let regionalCentersToFilter = event.detail.value.map(regionalCenter => regionalCenter.name);
        if (regionalCentersToFilter.length === 0) {
            regionalCentersToFilter = allRegionalCenters
            setSelectedRegionalCenters(allRegionalCenters)
        }
        const filtered = towns.filter(town =>
            town.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            selectedRegions.includes(town.region.name) &&
            regionalCentersToFilter.includes(getRegionalCenterByRegionName(town.region.name))
        );
        setFilteredItems(filtered);
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredItems, setFilteredItems] = useState(null);
    const handleSearchChange = (event) => {
        setCurrentPage(1)
        setSearchTerm(event.target.value);
        const filtered = towns.filter(town =>
            town.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
            town.id.toString().includes(event.target.value.toString()) &&
            selectedRegions.includes(town.region.name) &&
            selectedRegionalCenters.includes(getRegionalCenterByRegionName(town.region.name))
        );
        setFilteredItems(filtered);

    };
    const handleLetterClick = (letter) => {
        setCurrentPage(1)
        if (letter === '') {
            setLetterChosen(false)
        } else {
            setLetterChosen(true)
        }
        setSearchTerm(letter);
        if (towns.length > 0) {
            const filtered = towns.filter(town =>
                town.name.toLowerCase().startsWith(letter.toLowerCase()) &&
                selectedRegions.includes(town.region.name) &&
                selectedRegionalCenters.includes(getRegionalCenterByRegionName(town.region.name))
            );
            setFilteredItems(filtered);
        }

    };
    const currentItems = filteredItems ? filteredItems.slice(indexOfFirstItem, indexOfLastItem) : null;

    const [sortTownsOrder, setSortTownsOrder] = useState('asc');
    const sortTowns = () => {
        if (towns) {
            let sortedTowns = [...filteredItems];
            if (sortTownsOrder === 'asc') {
                sortedTowns.sort((a, b) => a.name.localeCompare(b.name));
            } else {
                sortedTowns.sort((a, b) => b.name.localeCompare(a.name));
            }
            setFilteredItems(sortedTowns);
        }

    };
    useEffect(() => {
        sortTowns();
    }, [sortTownsOrder]);

    const [sortIdOrder, setSortIdOrder] = useState('asc');
    const sortIdTowns = () => {
        if (towns) {
            let sortedTowns = [...filteredItems];
            if (sortIdOrder === 'asc') {
                sortedTowns.sort((a, b) => a.id - b.id);
            } else {
                sortedTowns.sort((a, b) => b.id - a.id);
            }
            setFilteredItems(sortedTowns);
        }

    };
    useEffect(() => {
        sortIdTowns();
    }, [sortIdOrder]);

    const [sortNumberOfProjectOrder, setSortNumberOfProjectOrder] = useState('asc');
    const sortNumberOfProjects = () => {
        if (towns) {
            let sortedTowns = [...filteredItems];
            if (sortNumberOfProjectOrder === 'asc') {
                sortedTowns.sort((a, b) => a.openProjectsCount - b.openProjectsCount);
            } else {
                sortedTowns.sort((a, b) => b.openProjectsCount - a.openProjectsCount);
            }
            setFilteredItems(sortedTowns);
        }

    };
    useEffect(() => {
        sortNumberOfProjects();
    }, [sortNumberOfProjectOrder]);

    const [sortVillagesLastConsultationDateOrder, setSortVillagesLastConsultationDateOrder] = useState('asc');

    const sortVillagesByLastConsultationDate = () => {
        if(towns){
            let sortedTowns = [...filteredItems];
            if (sortVillagesLastConsultationDateOrder === 'asc') {
                sortedTowns.sort((a, b) => new Date(a.lastConsultationDate) - new Date(b.lastConsultationDate));
            } else {
                sortedTowns.sort((a, b) => new Date(b.lastConsultationDate) - new Date(a.lastConsultationDate));
            }
            setFilteredItems(sortedTowns);
        }

    };

    useEffect(() => {
        sortVillagesByLastConsultationDate();
    }, [sortVillagesLastConsultationDateOrder]);

    const [sortTownContactedOrder, setSortTownContactedOrder] = useState('asc');
    const sortTownContacted = () => {
        if (towns) {
            let sortedTowns = [...filteredItems];
            if (sortTownContactedOrder === 'asc') {
                sortedTowns.sort((a, b) => a.contacted - b.contacted);
            } else {
                sortedTowns.sort((a, b) => b.contacted - a.contacted);
            }
            setFilteredItems(sortedTowns);
        }

    };
    useEffect(() => {
        sortTownContacted();
    }, [sortTownContactedOrder]);

    const fetchData = () => {
        localStorage.setItem("townsLastUpdate", new Date().toISOString())
        fetch(process.env.REACT_APP_API_URL + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setFilteredItems(jsonData);
                if (request.includes("/collective")) {
                    setTowns(jsonData);
                } else {
                    console.log("ttowns")
                    console.log(jsonData)
                    setTowns(jsonData);
                    setTownsForSelect(jsonData);
                    localStorage.setItem("towns", JSON.stringify(jsonData));

                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    useEffect(() => {
        // if (localStorage.getItem("towns") === null) {
            fetchData();
        // } else {
        //     console.log(JSON.parse(localStorage.getItem("towns")));
        //     setFilteredItems(JSON.parse(localStorage.getItem("towns")));
        //     setTowns(JSON.parse(localStorage.getItem("towns")));
        // }
    }, []);

    const townsListLastUpdate = localStorage.getItem("townsLastUpdate") ? new Date(localStorage.getItem("townsLastUpdate")) : new Date()
    const [townsListIsOld, setTownsListIsOld] = useState(new Date() - townsListLastUpdate > 3600000);

    function refreshTownList() {
        localStorage.removeItem("townsLastUpdate");
        localStorage.removeItem("towns");
        setTowns(null)
        setFilteredItems(null)
        fetchData()
        setTownsListIsOld(false)


    }

    const [DSOName, setDSOName] = useState("");
    const [DSONameInvalid, setDSONameInvalid] = useState(true);
    const [newSelectedTownsInvalid, setNewSelectedTownsInvalid] = useState(true);

    const handleDSONameChange = (event) => {
        if (event.target.value === "") {
            setDSONameInvalid(true);
        } else {
            setDSONameInvalid(false);
        }
        setDSOName(event.target.value);
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setDSOName("");

        setIsModalOpen(false);
    };

    const [selectedTowns, setSelectedTowns] = useState([]);
    const handleSelectTownsChange = (event) => {

        const newSelectedTowns = Array.from(event.detail.value, option => parseInt(option.value));
        newSelectedTowns.length === 0 ? setNewSelectedTownsInvalid(true) : setNewSelectedTownsInvalid(false)
        setSelectedTowns(newSelectedTowns);

    };

    function postNewDSO() {
        fetch(process.env.REACT_APP_API_URL + `/towns/collective`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "name": DSOName,
                    "townIds": selectedTowns
                }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                console.log("DSO added successfully");
                fetchData()
                closeModal();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    if (authority === "HEADQUARTERS") {
        request = "/towns";
    } else {
        request = "/rc/" + RCid + "/towns";
    }

    const [townType, setTownType] = useState("Obce");


    const setTypeOfView = (event) => {
        setTownType(event.detail.label)
        if (authority === "HEADQUARTERS") {
            if (event.detail.label === "Obce") {
                request = "/towns";
            } else {
                request = "/towns/collective";
            }
        } else {
            if (event.detail.label === "Obce") {
                // request = "/rc/"+RCid+"/employees";
                request = "/rc/" + RCid + "/towns";
            } else {
                request = "/towns/collective";
            }
        }
        setFilteredItems([])
        fetchData()
    }

    return (
        <div>
            <Navigation active={"villages"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Obce</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Přehled
                            obcí: {authority === "HEADQUARTERS" ? "" : regionalCentre}</div>
                        <GovTabs onGov-change={setTypeOfView} type="chip" variant="primary"
                                 wcag-label="Basic information about the gov.cz design system">
                            <GovTabsItem label="Obce"></GovTabsItem>
                            <GovTabsItem label="Dobrovolné sdružení obcí"/>
                        </GovTabs>
                        <div className="gov-flexbox search-row-width">
                            <GovFormInput onGov-input={handleSearchChange}
                                          placeholder="Zadejte název nebo kód obce" size="m"
                                          variant="secondary">
                                <GovIcon type="basic" name="search" slot="right-icon"></GovIcon>
                            </GovFormInput>
                        </div>
                        <GovSpacer size="3xl"></GovSpacer>
                        <GovFormControl>
                            <GovFormMultiSelect size="m" placeholder="Kraj" onGov-change={handleRegionChange}
                                                value={selectedRegions}>
                                {allRegions.map((region, index) => (
                                    <option key={index} value={region}>{region}</option>
                                ))}
                            </GovFormMultiSelect>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormMultiSelect size="m" placeholder="Regionální centrum"
                                                onGov-change={handleRegionalCenterChange}
                                                value={selectedRegionalCenters}>
                                {allRegionalCenters.map((center, index) => (
                                    <option key={index} value={center}>{center}</option>
                                ))}
                            </GovFormMultiSelect>
                        </GovFormControl>
                        {townsListIsOld &&
                            <GovMessage variant="warning"><p>Aktuálně pracujete se seznamem obcí, který byl naposledy
                                aktualizován před více než 1 hodinou. Pro načtení aktuálního seznamu obcí klikněte na
                                tlačítko níže.</p>
                                <GovIcon type="basic" name="info" slot="icon"></GovIcon>
                            </GovMessage>
                        }
                        <div className="gov-flexbox flex-space-between">
                            <GovButton class="mt-10" size="m" type="solid" wcag-label="Smazat" variant="primary"
                                       onGov-click={() => {
                                           refreshTownList()
                                       }}>Obnovit seznam obcí
                            </GovButton>

                                <div>
                                    {townsForSelect ?
                                    <GovButton class="ml-10 mt-10" size="m" type="solid" wcag-label="Smazat" variant="primary"
                                               onGov-click={() => {
                                                   openModal()
                                               }}>Přidat DSO
                                    </GovButton>
                                    :
                                    <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                                    animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                                    }
                                </div>


                        </div>
                        <div>
                            <p>Poslední aktualizace seznamu obcí
                                proběhla: {localStorage.getItem("townsLastUpdate") && convertIsoToCustomFormat(localStorage.getItem("townsLastUpdate"))}</p>
                        </div>

                        <div className="gov-container gov-flexbox gov-alphabet">
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('A')}>A
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('B')}>B
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('C')}>C
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Č')}>Č
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('D')}>D
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('E')}>E
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('F')}>F
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('G')}>G
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('H')}>H
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('CH')}>CH
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('I')}>I
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('J')}>J
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('K')}>K
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('L')}>L
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('M')}>M
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('N')}>N
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('O')}>O
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('P')}>P
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Q')}>Q
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('R')}>R
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Ř')}>Ř
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('S')}>S
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Š')}>Š
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('T')}>T
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('U')}>U
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('V')}>V
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('W')}>W
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('X')}>X
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Y')}>Y
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Z')}>Z
                            </GovButton>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                       size="m" onGov-click={() => handleLetterClick('Ž')}>Ž
                            </GovButton>
                            {letterChosen && <GovButton onGov-click={() => {
                                handleLetterClick('')
                            }} wcag-label="What exactly happens when you click?" variant="primary"
                                                        size="xs">Odstranit výběr
                            </GovButton>}
                        </div>
                        <GovSpacer size="xl"></GovSpacer>
                        {currentItems ?
                            <table cellSpacing="0" cellPadding="0">
                                <thead>
                                <tr>
                                    <th>{townType === "Obce" ? "Název obce" : "Název DSO"}
                                        {sortTownsOrder === "asc" ?
                                            <GovButton onGov-click={() => setSortTownsOrder('desc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                            :
                                            <GovButton onGov-click={() => setSortTownsOrder('asc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                        }
                                    </th>
                                    <th>{townType === "Obce" ? "Kód obce" : "Kód DSO"}
                                        {sortIdOrder === "asc" ?
                                            <GovButton onGov-click={() => setSortIdOrder('desc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                            :
                                            <GovButton onGov-click={() => setSortIdOrder('asc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                        }
                                    </th>
                                    <th>Kraj</th>
                                    <th>Regionální centrum</th>
                                    {/*<th>Počet obyvatel</th>*/}
                                    <th className="w-70px">Počet otevřených projektů
                                        {sortNumberOfProjectOrder === "asc" ?
                                            <GovButton onGov-click={() => setSortNumberOfProjectOrder('desc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                            :
                                            <GovButton onGov-click={() => setSortNumberOfProjectOrder('asc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                        }</th>
                                    <th className="w-70px">{townType === "Obce" ? "Obec byla oslovena" : "DSO bylo osloveno"}
                                        {sortTownContactedOrder === "asc" ?
                                            <GovButton onGov-click={() => setSortTownContactedOrder('desc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                            :
                                            <GovButton onGov-click={() => setSortTownContactedOrder('asc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                        }</th>
                                    <th className="w-150px">Poslední konzultace KKC
                                        {sortVillagesLastConsultationDateOrder === "asc" ?
                                            <GovButton onGov-click={() => setSortVillagesLastConsultationDateOrder('desc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-down"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                            :
                                            <GovButton onGov-click={() => setSortVillagesLastConsultationDateOrder('asc')}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary"
                                                       type="link" size="xs" loading="false">

                                                <GovIcon className="ml-5" type="basic" name="chevron-up"
                                                         slot="left-icon"></GovIcon> </GovButton>
                                        }
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentItems.map((row, index) => (
                                    <tr key={index}>
                                        <td className="first">
                                            {townType === "Obce" && !row.id.toString().startsWith('6') ?
                                                <a href={`/towns/detail/` + row.id}>{row.name}</a>
                                                :
                                                <a href={`/towns/collective/detail/` + row.id}>{row.name}</a>
                                            }
                                        </td>
                                        <td>{row.id}</td>
                                        <td>{row.region.name}</td>
                                        <td>{getRegionalCenterByRegionName(row.region.name)}</td>
                                        {/*<td>{row.population}</td>*/}
                                        <td>{row.openProjectsCount}</td>
                                        <td>{row.contacted === true ? "Ano" : "Ne"}</td>
                                        <td>{row.lastConsultationDate ? convertDateToDDMMYYYY(row.lastConsultationDate) : "Konzultace nebyla uskutečněna"}</td>
                                    </tr>
                                ))}
                                </tbody>

                            </table>
                            :
                            <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                         animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                        }
                    </div>

                </div>
                <div className="pagination-container">
                    {towns ? (
                        <GovPagination onGov-page={handlePageChange} variant="primary" size="s" type="button"
                                       current={currentPage} total={filteredItems.length} page-size={itemsPerPage}
                                       max-pages="4" wcag-label="Main paging" wcag-page-label="Page"></GovPagination>
                    ) : (
                        <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                     animation="progress" count="1" width="300px" height="30px"></GovSkeleton>
                    )}


                </div>
            </div>
            <GovModal open={isModalOpen} onGov-close={closeModal} label="Přidat dobrovolné sdružení obcí"
                      wcag-close-label="Close dialog box with more information">
                <GovFormControl>
                    <GovFormInput value={DSOName} onGov-input={handleDSONameChange} size="s"
                                  variant="secondary" input-type="text"
                                  placeholder=" Název dobrovolného sdružení obcí"></GovFormInput>
                    <GovFormLabel slot="top" size="s">Název dobrovolného sdružení obcí</GovFormLabel>
                </GovFormControl>
                <GovFormControl className="dso">
                    <GovFormLabel slot="top" size="xs">Zúčastněné obce*</GovFormLabel>

                    <GovFormMultiSelect size="s" onGov-change={handleSelectTownsChange}
                                        placeholder="Zúčastněné obce">
                        <div>
                            {townsForSelect && townsForSelect.length > 0 &&
                                <div>
                                    {townsForSelect.map((town) => (
                                        <option key={town.id}
                                                value={town.id}>{town.name + " (" + town.id + ")"}</option>
                                    ))
                                    }
                                </div>
                            }
                        </div>
                    </GovFormMultiSelect>
                </GovFormControl>
                <div>
                    {(DSONameInvalid || newSelectedTownsInvalid) &&
                        <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné
                            položky</p></GovMessage>}
                </div>
                <div>
                    {(selectedTowns.length < 2) &&
                        <GovMessage variant="error"><p>Dobrovolné sdružení obcí musí obsahovat nejméně dvě obce</p></GovMessage>}
                </div>
                <GovButton variant="primary" size="m" type="solid" wcag-label="Add new car"
                           onGov-click={postNewDSO}
                           disabled={DSONameInvalid || newSelectedTownsInvalid ? "true" : "false"}
                >
                    Uložit
                </GovButton>
            </GovModal>
        </div>
    )
}
