import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {VillageDetailContent} from "../components/contentComponents/VillageDetailContent";
import {useParams} from "react-router-dom";

export function VillageDetail() {
    const { id } = useParams();
    return (
        <div>
            <Header></Header>
            <VillageDetailContent  townId={id}></VillageDetailContent>
            <Footer></Footer>
        </div>
    )
}
