import {
    GovBreadcrumbs,
    GovButton,
    GovFormControl,
    GovFormGroup,
    GovFormInput, GovFormLabel, GovIcon,
    GovSpacer
} from "@gov-design-system-ce/react";
import {useNavigate,} from 'react-router-dom';
import '../css/password-reset.css';
import Navigation from "../Navigation";
import {useState} from "react";

export function PasswordResetContent() {

    const [personEmail, setPersonEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const handlePersonEmailChange = (event) => {
        setPersonEmail(event.target.value);
    };
    const validateForm = () => {
        let isValid = true;

        if (personEmail.trim() === '') {
            setEmailError(true);
            isValid = false;
        } else {
            setEmailError(false);
        }
        return isValid;
    };

    const navigate = useNavigate();


    function postForgottenPassword(){
        if(validateForm()){
            fetch(process.env.REACT_APP_API_URL+"/requestResetPassword", {
                method: 'POST', // Specify the method
                headers: {
                    'Content-Type': 'application/json',
                    // Include other headers here if needed
                },
                body: JSON.stringify({
                        "email": personEmail,
                    }
                ),
            })
                .then(response => response.json()) // Convert the response to JSON
                .then(data => {
                    console.log(data)
                    navigate("/")
                })
                .catch(error => {
                    console.error('Error:', error); // Handle any errors
                });
        }
    }




    const user = {
        role: "RC_LEADER" // or false based on the user's rights
        // Add other user attributes as needed
    };
    return (
        <div>
            <Navigation active={"home"} />
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Obnova hesla</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Obnova hesla</div>

                        <div className="sfpi-introduction-text">
                            Zadejte prosím Váš přihlašovací e-mail. Následně Vám na něj pošleme odkaz pro obnovu
                            hesla.
                        </div>
                        <GovSpacer size="m"></GovSpacer>
                        <form className="gov-form">
                            <GovFormControl>
                                <GovFormInput value={personEmail} onGov-input={handlePersonEmailChange}
                                              size="s" variant="secondary"
                                              input-type="text" placeholder="E-mail"></GovFormInput>
                                <GovFormLabel slot="top" size="s" >E-mail<span className={emailError ? 'label-error' : ''}>*</span></GovFormLabel>
                            </GovFormControl>
                            <GovSpacer size="l"></GovSpacer>
                            <div className="buttons-container">
                                <GovButton onGov-click={() => navigate(-1)}
                                           wcag-label="What exactly happens when you click?" variant="primary"
                                           type="solid" size="m">Zpět
                                </GovButton>
                                <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                           type="solid" size="m" onGov-click={postForgottenPassword}>Obnovit
                                    heslo</GovButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
