import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {ProfileEditContent} from "../components/contentComponents/ProfileEditContent";
import {useParams} from "react-router-dom";

export function ProfileEdit() {
    const { userId } = useParams();
    return (
        <div>
            <Header active={"profile-active"}></Header>
            <ProfileEditContent userId ={userId}></ProfileEditContent>
            <Footer></Footer>
        </div>
    )
}
