import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {RoadCompensationContent} from "../components/contentComponents/RoadCompensationContent";
import {useParams} from "react-router-dom";

export function RoadCompensation() {
    const { userId } = useParams();
    return (
        <div>
            <Header></Header>
            <RoadCompensationContent userId ={userId}></RoadCompensationContent>
            <Footer></Footer>
        </div>
    )
}
