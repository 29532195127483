import {Header} from "../components/Header";
import {ConsultationDetailContent} from "../components/contentComponents/ConsultationDetailContent";
import {Footer} from "../components/Footer";
import {EditConsultationContent} from "../components/contentComponents/EditConsultationContent";

export function EditConsultation() {
    return (
        <div>
            <Header></Header>
            <EditConsultationContent></EditConsultationContent>
            <Footer></Footer>
        </div>
    )
}
