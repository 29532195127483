import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs, GovButton,
    GovFormControl, GovFormFile,
    GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon, GovLoading, GovMessage, GovModal, GovPrompt, GovSpacer, GovTile, GovTiles
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import "../css/car-park.css";
import {useNavigate} from "react-router-dom";
import {fuelTypeToText, getIcon, parseJwt} from "../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function CarPageContent({carId}) {
    console.log(carId)
    const jwt = localStorage.getItem("jwt");
    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    if (user !== null) {
        role = user.role[0].authority
    }

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [notAuthorizedModalOpen, setNotAuthorizedModalOpen] = useState(false);

    function openDeleteModal() {
        setDeleteModalOpen(true)
    }

    function closeDeleteModal() {
        setDeleteModalOpen(false)
    }

    function openNotAuthorizedModal() {
        setNotAuthorizedModalOpen(true)
    }

    function closeNotAuthorizedModal() {
        setNotAuthorizedModalOpen(false)
        setDeleteModalOpen(false)
    }

    function deleteCar() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private/${carId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.status === 204) {
                    navigate(`/profile/cars`)
                } else {
                    return response.json().then(data => {
                        throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function fetchCar() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private/${carId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setCarName(jsonData.name)
                    setCarOnTrip(jsonData.onTrip)
                    setCarOwner(jsonData.user.firstname + " " + jsonData.user.lastname)
                    setCarLicensePlate(jsonData.licensePlate)
                    setCarFuelType(jsonData.fuelTypeId)
                    setCarConsumption(jsonData.consumption)
                    setCarInsurance(jsonData.carInsurance)
                    setCrashInsurance(jsonData.crashInsurance)
                    setTechnicalCertificate(jsonData.technicalCertificate)
                    setGreenCard(jsonData.greenCard)

                    setCarNameValid(!!jsonData.name)
                    setCarLicensePlateValid(!!jsonData.licensePlate)
                    setCarFuelTypeValid(!!jsonData.fuelTypeId)
                    setCarConsumptionValid(!!jsonData.consumption)

                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    function fetchDocument(type, documentName) {
        fetch(`${process.env.REACT_APP_API_URL}/vehicles/private/${carId}/download/${type}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = documentName;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }

    const navigate = useNavigate()
    const [editFields, setEditFields] = useState(false);
    const [carInsurance, setCarInsurance] = useState(null);
    const [crashInsurance, setCrashInsurance] = useState(null);
    const [technicalCertificate, setTechnicalCertificate] = useState(null);
    const [greenCard, setGreenCard] = useState(null);

    function putCar() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private/${carId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "name": carName,
                    "licensePlate": carLicensePlate,
                    "fuelTypeId": carFuelType,
                    "consumption": carConsumption
                }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                navigate("/profile/cars")
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    useEffect(() => {
        fetchCar()
    }, [])
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")

    const [carName, setCarName] = useState(null);
    const [carOnTrip, setCarOnTrip] = useState(false);
    const [carLicensePlate, setCarLicensePlate] = useState(null);
    const [carOwner, setCarOwner] = useState(null);
    const [carFuelType, setCarFuelType] = useState(null);
    const [carConsumption, setCarConsumption] = useState(null);

    const [carNameValid, setCarNameValid] = useState(false);
    const [carLicensePlateValid, setCarLicensePlateValid] = useState(false);
    const [carFuelTypeValid, setCarFuelTypeValid] = useState(false);
    const [carConsumptionValid, setCarConsumptionValid] = useState(false);

    const [carValid, setCarValid] = useState(false);

    function checkErrors() {
        let ready = carNameValid && carLicensePlateValid && carFuelTypeValid && carConsumptionValid
        console.log(carNameValid, carLicensePlateValid, carFuelTypeValid, carConsumptionValid)
        setCarValid(ready)
    }

    useEffect(() => {
        checkErrors()
    }, [carNameValid, carLicensePlateValid, carFuelTypeValid, carConsumptionValid]);

    const handleCarNameChange = (event) => {
        if (event.target.value !== "") {
            setCarNameValid(true);
        } else {
            setCarNameValid(false);
        }
        setCarName(event.target.value);
    };

    const handleCarIdChange = (event) => {
        if (event.target.value !== "") {
            setCarLicensePlateValid(true);
        } else {
            setCarLicensePlateValid(false);
        }
        setCarLicensePlate(event.target.value);
    };

    const handleCarFuelTypeChange = (event) => {
        if (event.target.value !== "") {
            setCarFuelTypeValid(true);
        } else {
            setCarFuelTypeValid(false);
        }
        setCarFuelType(event.target.value);
    };

    const handleCarConsumptionChange = (event) => {
        if (isNumeric(event.target.value) && parseFloat(event.target.value) > 0) {
            setCarConsumptionValid(true);
        } else {
            setCarConsumptionValid(false);
        }
        setCarConsumption(event.target.value);
    };

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    const [documentIsLoading, setDocumentIsLoading] = useState(false);

    function uploadDocument(file, type) {
        setDocumentIsLoading(true)
        const formData = new FormData();
        formData.append('file', file);


        fetch(`${process.env.REACT_APP_API_URL}/vehicles/private/${carId}/upload/${type}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                fetchCar()
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }


    return (
        <div>
            <Navigation/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/settings">Nastavení</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/profile/cars">Správa vlastního vozového parku</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>{carName} ({carLicensePlate})</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content vh-80">
                        <div className="gov-flexbox">
                            <div className="gov-title--beta">{carName}</div>
                            <div className="gov-flexbox--justify-end">
                                {carOnTrip ?
                                    <GovMessage variant="error"><p>Vozidlo nelze smazat, protože je přiřazeno k cestovní náhradě.</p>
                                    </GovMessage>
                                    :

                                    <GovButton size="m" type="solid" wcag-label="Smazat" variant="error"
                                               onGov-click={openDeleteModal}
                                               disabled={carOnTrip}>Smazat
                                    </GovButton>
                                }
                            </div>
                            <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                                       label="Smazat vozidlo" role="dialog" label-tag="h5"
                                       wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                                si přejete smazat toto vozidlo?<br/>Tato operace je nevratná.
                                <GovButton onGov-click={closeDeleteModal} variant="primary" size="m" type="base"
                                           slot="actions"
                                           wcag-label="Cancel delete file my-photo.jpg">Zrušit
                                </GovButton>
                                <GovButton variant="error" size="m" type="solid" slot="actions"
                                           wcag-label="Potvrdit smazání" onGov-click={deleteCar}>Smazat
                                </GovButton>
                            </GovPrompt>
                        </div>
                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder car-add">
                                {editFields ?


                                    <div className="gov-tabs__content is-active" role="tabpanel">
                                        <form method="post">
                                            <GovFormControl>
                                                <GovFormInput value={carName}
                                                              onGov-input={handleCarNameChange}
                                                              size="s" variant="secondary"
                                                              input-type="text"
                                                              placeholder="Číslo zaměstnance"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Název vozidla*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={carLicensePlate}
                                                              onGov-input={handleCarIdChange}
                                                              size="s" variant="secondary"
                                                              input-type="text"
                                                              placeholder="SPZ"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">SPZ*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormSelect size="s" variant="secondary" value={carFuelType}
                                                               onGov-change={handleCarFuelTypeChange}>
                                                    <option disabled="disabled" value="">Typ paliva</option>
                                                    <option value="3">Nafta</option>
                                                    <option value="1">Natural 95</option>
                                                    <option value="2">Natural 98</option>

                                                    <option value="4">Elektrický pohon</option>
                                                </GovFormSelect>
                                                <GovFormLabel slot="top" size="s">Typ paliva*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={carConsumption}
                                                              onGov-input={handleCarConsumptionChange}
                                                              size="s" variant="secondary"
                                                              input-type="number"
                                                              placeholder="Kombinovaná spotřeba dle technického průkazu"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Kombinovaná spotřeba dle technického
                                                    průkazu*</GovFormLabel>
                                            </GovFormControl>
                                            {!carValid &&
                                                <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné
                                                    položky</p>
                                                </GovMessage>}
                                            <GovButton className={"send-button"} onGov-click={putCar}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="primary" disabled={!carValid} type="solid" size="m">Uložit
                                            </GovButton>
                                            <GovButton className="ml-5" onGov-click={() => setEditFields(false)}
                                                       wcag-label="What exactly happens when you click?"
                                                       variant="error" type="link" size="m">Zrušit
                                            </GovButton>
                                        </form>
                                    </div>
                                    :

                                    <div className="gov-tabs__content is-active" role="tabpanel">
                                        <div>
                                            <h4>Majitel</h4>
                                            <p> {carOwner}</p>
                                        </div>
                                        <div>
                                            <h4>SPZ</h4>
                                            <p> {carLicensePlate}</p>
                                        </div>
                                        <div>
                                            <h4>Typ paliva</h4>
                                            <p> {fuelTypeToText(carFuelType)}</p>
                                        </div>
                                        <div>
                                            <h4>Kombinovaná spotřeba</h4>
                                            <p>{carConsumption}</p>

                                        </div>
                                        <GovButton className={"send-button"} onGov-click={() => setEditFields(true)}
                                                   wcag-label="What exactly happens when you click?"
                                                   variant="primary" type="link" size="m">Upravit
                                        </GovButton>
                                    </div>
                                }
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    <GovTiles columns="2">
                                        {!technicalCertificate ?
                                            <div className="padding-15">
                                                <GovFormControl>
                                                    <GovFormLabel slot="top" size="s">Technický průkaz</GovFormLabel>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => uploadDocument(event.detail.file.file, "TECHNICAL_CERTIFICATE")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                            </div>
                                            :
                                            <div className="padding-15">
                                                <h3>Technický průkaz</h3>
                                                <FontAwesomeIcon icon={getIcon(technicalCertificate.name)}/>
                                                <span> </span>
                                                <a href={technicalCertificate.link} onClick={(event) => {
                                                    event.preventDefault();
                                                    fetchDocument("TECHNICAL_CERTIFICATE", technicalCertificate.name);
                                                }}>
                                                    {technicalCertificate.name}
                                                </a>
                                                <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                    <GovFormControl>
                                                        <GovFormFile
                                                            onGov-add-file={(event) => uploadDocument(event.detail.file.file, "TECHNICAL_CERTIFICATE")}
                                                            max-file-size="616448"
                                                            accept=".pdf,.doc,.docx"
                                                            expanded="true"
                                                            displayAttachments="false">
                                                            <p>
                                                                <GovButton variant="primary" size="m"
                                                                           type="outlined">Nahrát soubor ze
                                                                    zařízení
                                                                </GovButton>
                                                            </p>
                                                            <GovSpacer size="m"></GovSpacer>
                                                            <p className="gov-text--s">Podporované formáty
                                                                PDF,
                                                                DOC</p></GovFormFile>
                                                    </GovFormControl>
                                                    <p slot="label">Nahrát jiný technický průkaz</p>
                                                </GovAccordionItem>
                                            </div>

                                        }
                                        {!crashInsurance ?
                                            <div className="padding-15">
                                                <GovFormControl>
                                                    <GovFormLabel slot="top" size="s">Havarijní pojištení</GovFormLabel>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => uploadDocument(event.detail.file.file, "CRASH_INSURANCE")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                            </div>
                                            :
                                            <div className="padding-15">
                                                <h3>Havarijní pojištení</h3>
                                                <FontAwesomeIcon icon={getIcon(crashInsurance.name)}/>
                                                <span> </span>
                                                <a href={crashInsurance.link} onClick={(event) => {
                                                    event.preventDefault();
                                                    fetchDocument("CRASH_INSURANCE", crashInsurance.name);
                                                }}>
                                                    {crashInsurance.name}
                                                </a>
                                                <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                    <GovFormControl>
                                                        <GovFormFile
                                                            onGov-add-file={(event) => uploadDocument(event.detail.file.file, "CRASH_INSURANCE")}
                                                            max-file-size="616448"
                                                            accept=".pdf,.doc,.docx"
                                                            expanded="true"
                                                            displayAttachments="false">
                                                            <p>
                                                                <GovButton variant="primary" size="m"
                                                                           type="outlined">Nahrát soubor ze
                                                                    zařízení
                                                                </GovButton>
                                                            </p>
                                                            <GovSpacer size="m"></GovSpacer>
                                                            <p className="gov-text--s">Podporované formáty
                                                                PDF,
                                                                DOC</p></GovFormFile>
                                                    </GovFormControl>
                                                    <p slot="label">Nahrát jiné havarijní pojištění</p>
                                                </GovAccordionItem>
                                            </div>

                                        }
                                        {!greenCard ?
                                            <div className="padding-15">
                                                <GovFormControl>
                                                    <GovFormLabel slot="top" size="s">Zelená karta</GovFormLabel>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => uploadDocument(event.detail.file.file, "GREEN_CARD")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                            </div>
                                            :
                                            <div className="padding-15">
                                                <h3>Zelená karta</h3>
                                                <FontAwesomeIcon icon={getIcon(greenCard.name)}/>
                                                <span> </span>
                                                <a href={greenCard.link} onClick={(event) => {
                                                    event.preventDefault();
                                                    fetchDocument("GREEN_CARD", greenCard.name);
                                                }}>
                                                    {greenCard.name}
                                                </a>
                                                <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                    <GovFormControl>
                                                        <GovFormFile
                                                            onGov-add-file={(event) => uploadDocument(event.detail.file.file, "GREEN_CARD")}
                                                            max-file-size="616448"
                                                            accept=".pdf,.doc,.docx"
                                                            expanded="true"
                                                            displayAttachments="false">
                                                            <p>
                                                                <GovButton variant="primary" size="m"
                                                                           type="outlined">Nahrát soubor ze
                                                                    zařízení
                                                                </GovButton>
                                                            </p>
                                                            <GovSpacer size="m"></GovSpacer>
                                                            <p className="gov-text--s">Podporované formáty
                                                                PDF,
                                                                DOC</p></GovFormFile>
                                                    </GovFormControl>
                                                    <p slot="label">Nahrát jinou zelenou kartu</p>
                                                </GovAccordionItem>
                                            </div>

                                        }
                                        {!carInsurance ?
                                            <div className="padding-15">
                                                <GovFormControl>
                                                    <GovFormLabel slot="top" size="s">Povinné ručení</GovFormLabel>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => uploadDocument(event.detail.file.file, "CAR_INSURANCE")}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="true"
                                                        displayAttachments="false">
                                                        <p>
                                                            <GovButton variant="primary" size="m"
                                                                       type="outlined">Nahrát soubor ze
                                                                zařízení
                                                            </GovButton>
                                                        </p>
                                                        <GovSpacer size="m"></GovSpacer>
                                                        <p className="gov-text--s">Podporované formáty
                                                            PDF,
                                                            DOC</p></GovFormFile>
                                                </GovFormControl>
                                            </div>
                                            :
                                            <div className="padding-15">
                                                <h3>Povinné ručení</h3>
                                                <FontAwesomeIcon icon={getIcon(carInsurance.name)}/>
                                                <span> </span>
                                                <a href={carInsurance.link} onClick={(event) => {
                                                    event.preventDefault();
                                                    fetchDocument("CAR_INSURANCE", carInsurance.name);
                                                }}>
                                                    {carInsurance.name}
                                                </a>
                                                <GovAccordionItem className="mt-10" variant="primary" size="xs">
                                                    <GovFormControl>
                                                        <GovFormFile
                                                            onGov-add-file={(event) => uploadDocument(event.detail.file.file, "CAR_INSURANCE")}
                                                            max-file-size="616448"
                                                            accept=".pdf,.doc,.docx"
                                                            expanded="true"
                                                            displayAttachments="false">
                                                            <p>
                                                                <GovButton variant="primary" size="m"
                                                                           type="outlined">Nahrát soubor ze
                                                                    zařízení
                                                                </GovButton>
                                                            </p>
                                                            <GovSpacer size="m"></GovSpacer>
                                                            <p className="gov-text--s">Podporované formáty
                                                                PDF,
                                                                DOC</p></GovFormFile>
                                                    </GovFormControl>
                                                    <p slot="label">Nahrát jiné povinné ručení</p>
                                                </GovAccordionItem>
                                            </div>

                                        }
                                    </GovTiles>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {documentIsLoading &&
                            <div>
                                <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
