// import './css/mayor-home.css'
import {GovAccordion, GovAccordionItem, GovButton, GovTile, GovTiles} from "@gov-design-system-ce/react";
import {parseJwt} from "../utils/utils";

export function UserHomePage() {

    const user = parseJwt(localStorage.getItem("jwt"))
    let role
    if(user !== null){
        role = user.role[0].authority
    }

    let tileData = [
        // { name: "Jak to funguje", text: "Prohlédněte si, jak celý proces funguje a s čím Vám můžeme pomoct.", url: "url1" },
        // { name: "Registrace", text: "Založte si jednoduchý profil a vytvořte svůj první projekt", url: "registration" },
        { name: "Projekty", text: "Přejděte na přehled projektů", url: "/projects" },
        { name: "Obce", text: "Zobrazte si přehled obcí a zvolte obec pro založení první konzultace", url: "towns/search" },
        { name: "Cestovní náhrady", text: "Přejděte na přehled cestovních náhrad", url:"/road-compensations"},
        { name: "Timesheet", text:"Přejděte na přehled timesheetů", url: "/timesheet"},
    ]

    if (role !== "RC_EMPLOYEE") {
        tileData.push({ name: "Správa uživatelů", text:"Zobrazte si přehled uživatelů a exteních osob", url: "/user-management"})
        tileData.push({ name: "Správa výkaznictví", text:"Zobrazte si stránku výkaznictví za zvolené regionální centrum", url: "/report-management"})
    }

    return (

        <div className="vh-80">
            <div className="gov-container">
                <div className="gov-container__content">
                    <h1>První kroky uživatele</h1>
                    <GovTiles>
                        {tileData.map((tile, index) => (
                            <GovTile key={index} href={tile.url}><p>{tile.text}</p>
                                <h3 slot="title">{tile.name}</h3>
                            </GovTile>
                        ))}
                    </GovTiles>
                </div>
            </div>

            <div className="gov-container">
                <div className="gov-container__content">
                    <div className="gov-layout gov-layout--ratio-1-2">
                        <div className="gov-layout__col gov-layout__col--pos-left"><h2
                            className="gov-title--alpha">Časté dotazy</h2></div>
                        <div className="gov-layout__col gov-layout__col--pos-right gov-layout__col--spread-2">
                            <GovAccordion variant="primary" size="xs"
                                          wcag-label="Basic information about the gov.cz design system">
                                <GovAccordionItem><p>Informace o Státním fondu podpory investic naleznete na <a
                                    className="gov-link--external" href="sfpi.cz">www.sfpi.cz</a></p><h3
                                    slot="label">Co znamená zkratka SFPI</h3></GovAccordionItem>
                                <GovAccordionItem><p>Nebojte se, chceme po vás jen osobní údaje, které jsou nezbytné pro
                                    správnou funkčnost portálu.
                                    Bližší informace o zpracování osobních údajů jsou umístěny <a
                                        className="gov-link--external" href="sfpi.cz/zpracovani-osobnich-udaju/">zde</a>
                                </p><h3
                                    slot="label">Jsou moje údaje v bezpečí?</h3></GovAccordionItem>
                            </GovAccordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}
