import Navigation from "../Navigation";
import {GovBreadcrumbs, GovButton, GovIcon, GovTile, GovTiles} from "@gov-design-system-ce/react";
import {externalRoleToText} from "../utils/utils";
import {useEffect, useState} from "react";

export function CarsPageContent() {
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")

    let [cars, setCarsArray]=useState([])
    let user = JSON.parse(localStorage.getItem("user"));
    let jwt = localStorage.getItem("jwt");

    function fetchCars() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private?userId=${user.id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setCarsArray(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    useEffect(() => {
        fetchCars()
    }, [])

    return (
        <div className="vh-100">
            <Navigation />
            <div className="gov-container">
                <div className="gov-container__content">
                    <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                        <ul>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/">Úvod</a></li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/settings">Nastavení</a>
                            </li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <strong>Správa vlastního vozového parku</strong>
                            </li>
                        </ul>
                    </GovBreadcrumbs>
                </div>
                <div className="gov-container">
                    <GovTiles columns="2">
                        {cars.length !== 0 && cars.map((row, index) => (
                            <GovTile key={index} href={`/profile/cars/${row.id}`}>
                                <p>Zobrazit detail vozidla.</p>
                                <h3 slot="title">{row.name} ({row.licensePlate})</h3>
                                <GovIcon type="basic" name="car" slot="icon"></GovIcon>
                            </GovTile>
                        ))}
                        <GovTile href="/profile/cars/add"><p>Navštívit formulář pro přidání vozidla.</p><h3
                            slot="title">Přidat vozidlo</h3>
                            <GovIcon type="basic" name="plus-circle" slot="icon"></GovIcon>
                        </GovTile>
                    </GovTiles>
                </div>
            </div>
        </div>
    )
}
