import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Home} from "./routes/Home";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import {Registration} from "./routes/Registration";
import {Login} from "./routes/Login";
import {PasswordReset} from "./routes/PasswordReset";
import {SettingNewPassword} from "./routes/SettingNewPassword";
import {FirstLogin} from "./routes/FirstLogin";
import {AddNewWorker} from "./routes/AddNewWorker";
import {NewConsultation} from "./routes/NewConsultation";
import {VillageSearch} from "./routes/VillageSearch";
import {VillageDetail} from "./routes/VillageDetail";
import {Projects} from "./routes/Projects";
import {ConsultationDetail} from "./routes/ConsultationDetail";
import {NewProject} from "./routes/NewProject";
import {ProjectDetail} from "./routes/ProjectDetail";
import {SyncConsultations} from "./routes/SyncConsultations";
import {ManageUsers} from "./routes/ManageUsers";
import {EditConsultation} from "./routes/EditConsultation";
import {EditProject} from "./routes/EditProject";
import {NewReport} from "./routes/NewReport";
import {RoadCompensation} from "./routes/RoadCompensation";
import {Timesheet} from "./routes/Timesheet";
import {Settings} from "./routes/Settings";
import {ProfileEdit} from "./routes/ProfileEdit";
import {CarPage} from "./routes/CarPage";
import {CarAdd} from "./routes/CarAdd";
import {CarsPage} from "./routes/CarsPage";
import {SFPICarsPage} from "./routes/SFPICarsPage";
import {UserMonthReports} from "./routes/UserMonthReports";
import {TimesheetOneMonth} from "./routes/TimesheetOneMonth";
import {RoadCompensationOneMonth} from "./routes/RoadCompensationOneMonth";
import {MMRExport} from "./routes/MMRExport";
import {UsersCarsPage} from "./routes/UsersCarsPage";
import {TownCollectiveDetail} from "./routes/TownCollectiveDetail";
import {MonthReportManagement} from "./routes/MonthReportManagement";
import {Dashboard} from "./routes/Dashboard";
import {SystemMaintenanceContent} from "./components/maintenanceComponents/SystemMaintenanceContent";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
        <Router>
            <Routes>
                <Route path="/" element={<DevSupport ComponentPreviews={ComponentPreviews}
                                                         useInitialHook={useInitial}
                >
                    <Home/>
                </DevSupport>}/>
                <Route path="/login" element={<DevSupport ComponentPreviews={ComponentPreviews}
                                                                 useInitialHook={useInitial}
                >
                    <Login/>
                </DevSupport>}/>
                <Route path="/passwordreset" element={<DevSupport ComponentPreviews={ComponentPreviews}
                                                          useInitialHook={useInitial}
                >
                    <PasswordReset/>
                </DevSupport>}/>
                <Route path="/newpassword" element={<DevSupport ComponentPreviews={ComponentPreviews}
                                                                  useInitialHook={useInitial}
                >
                    <SettingNewPassword/>
                </DevSupport>}/>
                <Route path="/firstlogin" element={<DevSupport ComponentPreviews={ComponentPreviews}
                                                                  useInitialHook={useInitial}
                >
                    <FirstLogin/>
                </DevSupport>}/>
                <Route path="/import" element={<SyncConsultations />} />
                <Route path="/mmr-export" element={<MMRExport />} />
                <Route path="/new-project/:townId" element={<NewProject />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/projects/detail/:projectId" element={<ProjectDetail />} />
                <Route path="/edit-project/:projectId" element={<EditProject />} />
                <Route path="/new-consultation/:townId" element={<NewConsultation />} />
                <Route path="/new-consultation/detail/:consultationId" element={<ConsultationDetail />} />
                <Route path="/edit-consultation/:consultationId" element={<EditConsultation />} />
                <Route path="/add-new-worker" element={<AddNewWorker />} />
                <Route path="/towns/detail/:id" element={<VillageDetail />} />
                <Route path="/towns/collective/detail/:id" element={<TownCollectiveDetail />} />
                <Route path="/towns/search" element={<VillageSearch />} />
                <Route path="/user-management" element={<ManageUsers />} />
                <Route path="/user-management/month-reports/:userId" element={<UserMonthReports />} />
                <Route path="/timesheet/" element={<Timesheet />} />
                <Route path="/timesheet/:userId" element={<Timesheet />} />
                <Route path="/timesheet/month-report/:monthReportId" element={<TimesheetOneMonth />} />

                <Route path="/road-compensations" element={<RoadCompensation />} />
                <Route path="/road-compensations/:userId" element={<RoadCompensation />} />
                <Route path="/road-compensations/month-report/:monthReportId" element={<RoadCompensationOneMonth />} />
                <Route path="/report-management" element={<MonthReportManagement />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/profile/edit" element={<ProfileEdit />} />
                <Route path="/profile/edit/:userId" element={<ProfileEdit />} />
                <Route path="/profile/cars" element={<CarsPage />} />
                <Route path="/settings/cars/sfpi" element={<SFPICarsPage />} />
                <Route path="/settings/cars/users" element={<UsersCarsPage />} />
                <Route path="/profile/cars/add" element={<CarAdd />} />
                <Route path="/profile/cars/:id" element={<CarPage />} />

                <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
        </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
