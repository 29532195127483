import {
    GovBreadcrumbs,
    GovButton,
    GovFormControl,
    GovFormGroup,
    GovFormInput, GovFormLabel, GovIcon, GovMessage,
    GovSpacer
} from "@gov-design-system-ce/react";
import '../css/registration.css';
import Navigation from "../Navigation";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export function SettingNewPasswordContent() {

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const verifyTokenParam = query.get('verifyToken');

    const [personEmail, setPersonEmail] = useState('');
    const [personPassword, setPersonPassword] = useState('');
    const [personPasswordConfirm, setPersonPasswordConfirm] = useState('');

    const handlePersonEmailChange = (event) => {
        setPersonEmail(event.target.value);
    };

    const handlePersonPasswordChange = (event) => {
        setPersonPassword(event.target.value);
    };

    const handlePersonPasswordConfirmChange = (event) => {
        setPersonPasswordConfirm(event.target.value);
    };

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordConfirmError, setPasswordConfirmError] = useState(false);

    function validatePassword(password) {
        // Password should be at least 10 characters long, contain at least one number, one lowercase and one uppercase letter
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
        return re.test(String(password));
    }

    const validateForm = () => {
        let isValid = true;

        if (personEmail.trim() === '') {
            setEmailError(true);
            isValid = false;
        } else {
            setEmailError(false);
        }

        if (!validatePassword(personPassword.trim())) {
            setPasswordError(true);
            isValid = false;
        } else {
            setPasswordError(false);
        }

        if (personPasswordConfirm.trim() === '') {
            setPasswordConfirmError(true);
            isValid = false;
        } else if (personPassword !== personPasswordConfirm) {
            setPasswordConfirmError(true);
            isValid = false;
        } else {
            setPasswordConfirmError(false);
        }

        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateForm()) {
            postResetPassword();
        }
    };

    const navigate = useNavigate();


    function postResetPassword(){
        fetch(process.env.REACT_APP_API_URL+"/resetPassword", {
            method: 'POST', // Specify the method
            headers: {
                'Content-Type': 'application/json',
                // Include other headers here if needed
            },
            body: JSON.stringify({
                    "email": personEmail,
                    "newPassword": personPassword,
                    "token": verifyTokenParam
                }
            ),
        })
            .then(response => response.json()) // Convert the response to JSON
            .then(data => {
                navigate("/login")
                console.log(data)
            })
            .catch(error => {
                console.error('Error:', error); // Handle any errors
            });


    }


    return (
        <div>
            <Navigation active={"home"} />
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Volba nového hesla</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">
                        <div className="gov-title--beta">Volba nového hesla</div>
                        <form className="gov-form">

                            <GovFormControl>
                                <GovFormInput value={personEmail} onGov-input={handlePersonEmailChange}
                                              size="s" variant="secondary"
                                              input-type="text" placeholder="E-mail"></GovFormInput>
                                <GovFormLabel slot="top" size="s">E-mail<span
                                    className={emailError ? 'label-error' : ''}>*</span></GovFormLabel>
                            </GovFormControl>
                            <GovFormControl>
                                <GovFormInput value={personPassword} onGov-input={handlePersonPasswordChange}
                                              size="s" variant="secondary"
                                              input-type="password" placeholder="Heslo"></GovFormInput>
                                <GovFormLabel slot="top" size="s">Heslo<span
                                    className={passwordError ? 'label-error' : ''}>*</span></GovFormLabel>


                            </GovFormControl>
                            <GovFormControl>
                                <GovFormInput value={personPasswordConfirm}
                                              onGov-input={handlePersonPasswordConfirmChange}
                                              size="s" variant="secondary"
                                              input-type="password" placeholder="Potvrzení hesla"></GovFormInput>
                                <GovFormLabel slot="top" size="s">Potvrzení hesla<span
                                    className={passwordConfirmError ? 'label-error' : ''}>*</span></GovFormLabel>
                            </GovFormControl>
                            <GovFormControl>
                                <div>
                                    {emailError &&
                                        <GovMessage className="w-100" variant="error">
                                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                            <p>Email musí být vyplněn</p>
                                        </GovMessage>
                                    }
                                </div>
                                <div>
                                    {passwordError &&
                                        <GovMessage className="w-100" variant="error">
                                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                            <p>Heslo musí obsahovat nejměně 10 znaků, číslici, velké a malé písmeno</p>
                                        </GovMessage>
                                    }
                                </div>
                                <div>
                                    {passwordConfirmError &&
                                        <GovMessage className="w-100" variant="error">
                                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                            <p>Hesla se neshodují</p>
                                        </GovMessage>
                                    }
                                </div>
                            </GovFormControl>
                            <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="solid"
                                       size="m" onGov-click={handleSubmit}>Potvrdit
                            </GovButton>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
