import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {ManageUsersContent} from "../components/contentComponents/ManageUsersContent";

export function ManageUsers() {
    let user = {
        role: 'RC_LEADER'
    }
    return (
        <div>
            <Header></Header>
            <ManageUsersContent user={user}></ManageUsersContent>
            <Footer></Footer>
        </div>
    )
}