import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {LoginContent} from "../components/contentComponents/LoginContent";
import {parseJwt} from "../components/utils/utils";

export function Login() {
    return (
        <div>
            <Header></Header>
            <LoginContent></LoginContent>
            <Footer></Footer>
        </div>
    )
}
