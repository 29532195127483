import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovButton, GovFormControl, GovFormInput,
    GovFormLabel,
    GovFormSelect,
    GovIcon, GovMessage, GovModal, GovPrompt,
    GovTile,
    GovTiles
} from "@gov-design-system-ce/react";
import {externalRoleToText} from "../utils/utils";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export function SFPICarsPageContent() {
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")

    let [cars, setCarsArray]=useState([])
    let user = JSON.parse(localStorage.getItem("user"));
    let jwt = localStorage.getItem("jwt");

    function fetchSFPICars() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/company`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setCarsArray(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    useEffect(() => {
        fetchSFPICars()
    }, [])

    const allRegionalCenters = [
        "Moravskoslezský kraj",
        "Středočeský kraj a Praha",
        "Ústecký a Karlovarský kraj",
        "Královehradecký a Liberecký kraj",
        "Pardubický kraj a Kraj Vysočina",
        "Jihočeský a Plzeňský kraj",
        "Zlínský a Jihomoravský kraj",
        "Olomoucký kraj"
    ];
    const [currentCarId, setCurrentCarId] = useState();
    const [carName, setCarName] = useState("");
    const [carEdit, setCarEdit] = useState(true);
    const [licensePlate, setLicensePlate] = useState("");
    const [regionalCenter, setRegionalCenter] = useState("");
    const [regionalCenters, setRegionalCenters] = useState(allRegionalCenters); // Assuming `allRegionalCenters` is already defined
    const [isModalOpen, setIsModalOpen] = useState(false);

// Handlers for input changes
    const handleCarNameChange = (event) => {
        setCarName(event.target.value);
    };

    const handleLicensePlateChange = (event) => {
        setLicensePlate(event.target.value);
    };

    const handleRegionalCenterChange = (event) => {
        setRegionalCenter(event.target.value);
    };

// Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

// Function to close the modal
    const closeModal = () => {
        setCurrentCarId(null)
        setCarEdit(true)
        setCarName("");
        setLicensePlate("");
        setRegionalCenter("");
        setIsModalOpen(false);
    };

    function postSFPICar() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/company`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "name": carName,
                    "licensePlate": licensePlate,
                    "regionalCenterId": regionalCenter
                }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                console.log("Car added successfully");
                fetchSFPICars()
                closeModal();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    function putSFPICar() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/company/${currentCarId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "name": carName,
                    "licensePlate": licensePlate,
                    "regionalCenterId": regionalCenter
                }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                console.log("Car updated successfully");
                fetchSFPICars()
                closeModal();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }
    function setCurrentCar(carId) {
        setCarEdit(false)
        fetchSFPICar(carId)
        openModal()
    }

    function fetchSFPICar(carId) {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/company/${carId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    setCurrentCarId(jsonData.id)
                    setCarName(jsonData.name)
                    setLicensePlate(jsonData.licensePlate)
                    setRegionalCenter(jsonData.regionalCenter.id)
                    setCarOnTrip(jsonData.onTrip)
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [carOnTrip, setCarOnTrip] = useState(false);
    const navigate = useNavigate()
    function openDeleteModal() {
        setDeleteModalOpen(true)
    }
    function closeDeleteModal() {
        setDeleteModalOpen(false)
    }

    function deleteCar() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/company/${currentCarId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'DELETE',
        })
            .then(response => {
                console.log(response)
                if (response.status === 204) {
                    window.location.reload()
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                } else {
                    return response.json().then(data => {
                        throw new Error(`Unexpected response: ${data.message || 'No message provided'}`);
                    });
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <div className="vh-100">
            <Navigation />
            <div className="gov-container">
                <div className="gov-container__content">
                    <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                        <ul>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/">Úvod</a></li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/settings">Nastavení</a>
                            </li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <strong>Správa vozového parku SFPI</strong>
                            </li>
                        </ul>
                    </GovBreadcrumbs>
                </div>
                <div className="gov-container">
                    <GovTiles columns="2">
                        {cars.length !== 0 && cars.map((row, index) => (
                            <GovTile key={index} href="#" onClick={() => setCurrentCar(row.id)}>
                                <p>Zobrazit detail vozidla.</p>
                                <h3 slot="title">{row.name} ({row.licensePlate})</h3>
                                <GovIcon type="basic" name="car" slot="icon"></GovIcon>
                            </GovTile>
                        ))}
                        <GovTile href="#" onClick={openModal}><p>Navštívit formulář pro přidání vozidla.</p><h3
                            slot="title">Přidat vozidlo</h3>
                            <GovIcon type="basic" name="plus-circle" slot="icon"></GovIcon>
                        </GovTile>
                    </GovTiles>
                    <GovModal open={isModalOpen} onGov-close={closeModal} label={carEdit ? "Přidat firemní vozidlo" : "Upravit firemní vozidlo"}  wcag-close-label="Close dialog box with more information">
                        {carEdit ?
                            <div>
                                <GovFormControl>
                                    <GovFormInput value={carName} onGov-input={handleCarNameChange} size="s" variant="secondary" input-type="text" placeholder="Název vozidla"></GovFormInput>
                                    <GovFormLabel slot="top" size="s">Název vozidla</GovFormLabel>
                                </GovFormControl>
                                <GovFormControl>
                                    <GovFormInput value={licensePlate} onGov-input={handleLicensePlateChange} size="s" variant="secondary" input-type="text" placeholder="SPZ"></GovFormInput>
                                    <GovFormLabel slot="top" size="s">SPZ</GovFormLabel>
                                </GovFormControl>
                                <GovFormControl>
                                    <GovFormSelect size="s" placeholder="Regionální centrum" onGov-change={handleRegionalCenterChange} value={regionalCenter}>
                                        <option disabled="disabled" value="">Vyberte regionální centrum</option>
                                        {regionalCenters.map((center, index) => (
                                            <option key={index} value={index+1}>{center}</option>
                                        ))}
                                    </GovFormSelect>
                                    <GovFormLabel slot="top" size="s">Regionální centrum*</GovFormLabel>
                                </GovFormControl>
                                <GovButton variant="primary" size="m" type="solid" wcag-label="Add new car" onGov-click={currentCarId ? putSFPICar : postSFPICar}>
                                    Uložit
                                </GovButton>
                            </div> :
                            <div>
                                <h4>Název vozidla</h4>
                                <p>{carName}</p>
                                <h4>SPZ</h4>
                                <p>{licensePlate}</p>
                                <h4>Regionální centrum</h4>
                                <p>{allRegionalCenters[regionalCenter - 1]}</p>

                                <GovButton variant="primary" size="m" type="link" wcag-label="Add new car"
                                           onGov-click={() => setCarEdit(true)}>
                                    Upravit
                                </GovButton>
                                <div>
                                    {carOnTrip ?
                                        <GovMessage variant="error"><p>Vozidlo nelze smazat, protože je přiřazeno k
                                            cestovní náhradě.</p>
                                        </GovMessage>
                                        :

                                        <GovButton size="m" type="link" wcag-label="Smazat" variant="error"
                                                   onGov-click={openDeleteModal}
                                                   disabled={carOnTrip}>Smazat
                                        </GovButton>
                                    }
                                </div>
                            </div>
                        }
                    </GovModal>
                </div>
                <GovPrompt open={deleteModalOpen} onGov-close={closeDeleteModal}
                           label="Smazat vozidlo" role="dialog" label-tag="h5"
                           wcag-close-label="Closes the prompt to delete the file my-photo.jpg">Opravdu
                    si přejete smazat toto vozidlo?<br/>Tato operace je nevratná.
                    <GovButton onGov-click={closeDeleteModal} variant="primary" size="m" type="base"
                               slot="actions"
                               wcag-label="Cancel delete file my-photo.jpg">Zrušit
                    </GovButton>
                    <GovButton variant="error" size="m" type="solid" slot="actions"
                               wcag-label="Potvrdit smazání" onGov-click={deleteCar}>Smazat
                    </GovButton>
                </GovPrompt>
            </div>
        </div>
    )
}
