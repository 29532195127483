import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {AddNewWorkerContent} from "../components/contentComponents/AddNewWorkerContent";

export function AddNewWorker() {
    const user = 'mayor'
    return (
        <div>
            <Header></Header>
            <AddNewWorkerContent user={user}></AddNewWorkerContent>
            <Footer></Footer>
        </div>
    )
}
