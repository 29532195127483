import Navigation from "../Navigation";
import {parseJwt} from "../utils/utils";

export function HomeHeader() {
    let user
    if(localStorage.getItem("jwt") !== null){
        const userDetails = parseJwt(localStorage.getItem("jwt"))
        if(localStorage.getItem("jwt")){
            user = {
                role: userDetails.role[0].authority
            }
        }
    }

    return (
        <div>
            <Navigation active={"home"}/>
            <div className="gov-container u-bg-color--blue">
                <div className="gov-container__content">
                    <div className="gov-hero-banner gov-hero-banner--inversed gov-hero-banner--branded">
                        <div className="gov-hero-banner__content"><h1
                            className="gov-hero-banner__headline u-color--white" tabIndex="-1">
                            Poradenský portál SFPI
                        </h1>
                            <div className="sfpi-introduction-text u-color--white">
                                Regionální centra podpory investic pro bydlení nabízí zejména obcím pomoc s projekty Dostupného bydlení.
                                Prohlédněte si jak, celý proces funguje a založte svůj první projekt.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
