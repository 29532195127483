import {
    GovButton,
    GovFormControl, GovFormFile, GovFormGroup,
    GovFormInput,
    GovFormLabel,
    GovFormSelect, GovIcon, GovLoading, GovMessage, GovModal,
    GovSkeleton, GovTabs, GovTabsItem
} from "@gov-design-system-ce/react";
import Select, {components} from "react-select";
import {MenuList} from "./MenuList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertIsoToLocalDate, convertIsoToTime, getIcon, parseJwt} from "../utils/utils";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

export function RoadCompensationModal(props) {

    const jwt = localStorage.getItem("jwt")

    let user = props.user ? props.user : JSON.parse(localStorage.getItem("user"));

    const navigate = useNavigate();

    const [modalOpen, setModalOpen] = useState(true);
    const [pageView, setPageView] = useState("Cesta");
    const [transportType, setTransportType] = useState("2");
    const [workReportType, setWorkReportType] = useState("6");
    const [selectedCarId, setSelectedCarId] = useState('')
    const [carsSFPI, setCarsSFPI] = useState([])
    const [cars, setCarsArray] = useState([])
    const [preProjectConsultations, setPreProjectConsultations] = useState([])
    const [projects, setProjects] = useState([])
    const [villages, setVillages] = useState([])
    const [selectedPreProjectConsultationId, setSelectedPreProjectConsultationId] = useState('')
    const [selectedProjectId, setSelectedProjectId] = useState('')
    const [selectedVillageId, setSelectedVillageId] = useState('')
    const [fromPlace, setFromPlace] = useState('')
    const [toPlace, setToPlace] = useState('')
    const [roadCompensationDate, setRoadCompensationDate] = useState('2024-01-01')
    const [roadDistance, setRoadDistance] = useState('')
    const [foodCountProvided, setFoodCountProvided] = useState(0)
    const [roadCompensationDuration, setRoadCompensationDuration] = useState(null)
    const [roadCompensationStartTime, setRoadCompensationStartTime] = useState('')
    const [roadCompensationEndTime, setRoadCompensationEndTime] = useState('')
    const [roadCompensationDescription, setRoadCompensationDescription] = useState('')
    const [nameOfExpense, setNameOfExpense] = useState("");
    const [costOfExpense, setCostOfExpense] = useState("");
    const [filteredItems, setFilteredItems] = useState(null);
    let [roadCompensations, setRoadCompensations] = useState(null)
    const [currentRoadCompensationId, setCurrentRoadCompensationId] = useState(null);
    const [fuelConsumption, setFuelConsumption] = useState(0)
    const [expenses, setExpenses] = useState([]);

    const [selectedPreProjectConsultationIdInvalid, setSelectedPreProjectConsultationIdInvalid] = useState(true)
    const [selectedProjectIdInvalid, setSelectedProjectIdInvalid] = useState(true)
    const [selectedVillageIdInvalid, setSelectedVillageIdInvalid] = useState(true)
    const [roadCompensationFromPlaceInvalid, setRoadCompensationFromPlaceInvalid] = useState(true)
    const [roadCompensationToPlaceInvalid, setRoadCompensationToPlaceInvalid] = useState(true)
    const [roadCompensationDistanceInvalid, setRoadCompensationDistanceInvalid] = useState(true)
    const [passengerInvalid, setPassengerInvalid] = useState(false)
    const [foodCountProvidedInvalid, setFoodCountProvidedInvalid] = useState(true)
    const [roadCompensationDateInvalid, setRoadCompensationDateInvalid] = useState(true)
    const [timesheetTypeInvalid, setTimesheetTypeInvalid] = useState(false)
    const [roadCompensationStartTimeInvalid, setRoadCompensationStartTimeInvalid] = useState(true)
    const [roadCompensationEndTimeInvalid, setRoadCompensationEndTimeInvalid] = useState(true)
    const [roadCompensationDescriptionInvalid, setRoadCompensationDescriptionInvalid] = useState(true)
    const [roadCompensationTimesRangeInvalid, setRoadCompensationTimesRangeInvalid] = useState(true)

    const [formValid, setFormValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleNameChange = (event) => setNameOfExpense(event.target.value);
    const handleCostChange = (event) => setCostOfExpense(event.target.value);

    const [roadCompensationValid, setRoadCompensationValid] = useState(false)

    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");

    function calculateHoursWorkedForEdit(startTime, endTime) {
        const start = new Date(`${startTime}`);
        const end = new Date(`${endTime}`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setRoadCompensationTimesRangeInvalid(true)
            return 0
        } else {
            setRoadCompensationTimesRangeInvalid(false)
            return result.toFixed(2)
        }
    }

    function calculateHoursWorkedForDateChange(date, startTime, endTime) {
        const start = new Date(`${date}T${startTime}:00`);
        const end = new Date(`${date}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)

        if (isNaN(result) || result <= 0) {
            setRoadCompensationTimesRangeInvalid(true)
            return 0
        } else {
            const decimalPart = result % 1;
            const validEndings = [0.00, 0.25, 0.50, 0.75];

            if (!validEndings.includes(decimalPart)) {
                setRoadCompensationTimesRangeInvalid(true);
                return result.toFixed(2);
            } else {
                setRoadCompensationTimesRangeInvalid(false);
                return result.toFixed(2);
            }
        }
    }

    function putRoadCompensation() {
        setModalOpen(false)
        fetch(process.env.REACT_APP_API_URL + `/workTrips/${currentRoadCompensationId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            method: 'PUT',
            body: JSON.stringify({
                "description": roadCompensationDescription,
                "note": "",
                "transportType": transportType,
                "from": fromPlace,
                "to": toPlace,
                "mealsCount": foodCountProvided,
                "distance": roadDistance,
                "privateVehicleId": transportType === "2" ? selectedCarId : null,
                "companyVehicleId": transportType === "3" ? selectedCarId : null,
                "start": roadCompensationDate + "T" + roadCompensationStartTime + ":00",
                "end": roadCompensationDate + "T" + roadCompensationEndTime + ":00",
                "workReportType": workReportType,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId === "" ? null : selectedVillageId,
                "preProjectConsultationId": selectedPreProjectConsultationId === "" ? null : selectedPreProjectConsultationId
            })
        })
            .then(response => {
                if (response.status === 409) {
                    alert("Chyba: Cestovní náhrada koliduje s jinou cestovní náhradou nebo timesheetem.");
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                props.onGovModalClose();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                props.onGovModalClose();
            });
    }

    function postRoadCompensation() {
        fetch(process.env.REACT_APP_API_URL + "/workTrips", {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "description": roadCompensationDescription,
                "note": "",
                "transportType": transportType,
                "from": fromPlace,
                "to": toPlace,
                "mealsCount": foodCountProvided,
                "distance": roadDistance === "" ? 0 : roadDistance,
                "privateVehicleId": transportType === "2" ? selectedCarId : null,
                "companyVehicleId": transportType === "3" ? selectedCarId : null,
                "start": roadCompensationDate + "T" + roadCompensationStartTime + ":00",
                "end": roadCompensationDate + "T" + roadCompensationEndTime + ":00",
                "workReportType": workReportType,
                "projectId": selectedProjectId === "" ? null : selectedProjectId,
                "townId": selectedVillageId === "" ? null : selectedVillageId,
                "preProjectConsultationId": selectedPreProjectConsultationId === "" ? null : selectedPreProjectConsultationId
            })
        })
            .then(response => {
                if (response.status === 409) {
                    alert("Chyba: Cestovní náhrada koliduje s jinou cestovní náhradou nebo timesheetem.");
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((jsonData) => {
                openRoadCompensationModalForAdditionalExpenses(jsonData.id);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    function calculateRoadTripDuration(startTime, endTime) {
        const start = new Date(`${roadCompensationDate}T${startTime}:00`);
        const end = new Date(`${roadCompensationDate}T${endTime}:00`);
        const result = (end - start) / (1000 * 60 * 60)
        if (isNaN(result) || result <= 0) {
            setRoadCompensationTimesRangeInvalid(true)
            return 0
        } else {
            setRoadCompensationTimesRangeInvalid(false)
            return result.toFixed(2)
        }
    }

    const handleSettingPageView = (event) => {
        setPageView(event.detail.label)
    }

    function fetchCurrentWorkTripCompensation(id) {
        let roadCompensation;
        fetch(process.env.REACT_APP_API_URL + `/workTrips/${id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            roadCompensation = jsonData;
            setCurrentRoadCompensationId(roadCompensation.id)

            setExpenses(roadCompensation.expenses)
            setFromPlace(roadCompensation.from)
            setToPlace(roadCompensation.to)
            setRoadDistance(roadCompensation.distance)
            setFoodCountProvided(roadCompensation.mealsCount)
            setRoadCompensationDate(convertIsoToLocalDate(roadCompensation.workReport.start))
            setRoadCompensationStartTime(convertIsoToTime(roadCompensation.workReport.start))
            setRoadCompensationEndTime(convertIsoToTime(roadCompensation.workReport.end))
            setRoadCompensationDuration(calculateHoursWorkedForEdit(roadCompensation.workReport.start, roadCompensation.workReport.end))
            setWorkReportType(roadCompensation.workReport.type)
            setTransportType(roadCompensation.transportType.toString())
            console.log(roadCompensation.workReport.town ? roadCompensation.workReport.town.id : '')


            if (roadCompensation.privateVehicle) {
                setSelectedCarId(roadCompensation.privateVehicle.id);
                setFuelConsumption(roadCompensation.privateVehicle.consumption);
            } else if (roadCompensation.companyVehicle) {
                setSelectedCarId(roadCompensation.companyVehicle.id);
                setFuelConsumption(roadCompensation.companyVehicle.consumption);
            } else {
                setSelectedCarId(null); // or any default value
                setFuelConsumption(0); // or any default value
            }

            setRoadCompensationDescription(roadCompensation.description)

            setSelectedProjectId(roadCompensation.workReport.project ? roadCompensation.workReport.project.id : '')
            setSelectedVillageId(roadCompensation.workReport.town ? roadCompensation.workReport.town.id : '')
            setSelectedPreProjectConsultationId(roadCompensation.workReport.preProjectConsultationId ? roadCompensation.workReport.preProjectConsultationId : '')

            setSelectedPreProjectConsultationIdInvalid(false)
            setSelectedProjectIdInvalid(false)
            setSelectedVillageIdInvalid(false)
            setRoadCompensationDateInvalid(false)
            setRoadCompensationStartTimeInvalid(false)
            setRoadCompensationEndTimeInvalid(false)
            setRoadCompensationDescriptionInvalid(false)
            setRoadCompensationTimesRangeInvalid(false)
            setRoadCompensationDistanceInvalid(false)
            setRoadCompensationFromPlaceInvalid(false)
            setRoadCompensationToPlaceInvalid(false)
            setRoadCompensationValid(true)

        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const postWorkTripExpense = () => {
        if (!formValid) {
            setErrorMessage("Prosím vyplňte všechny povinné položky.");
            return;
        }


        fetch(process.env.REACT_APP_API_URL + `/workTrips/${currentRoadCompensationId}/expenses`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("jwt")}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "workTrip": currentRoadCompensationId,
                "name": nameOfExpense,
                "cost": costOfExpense,
                "documentId": null
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log("Expense submitted successfully:", data);
                fetchCurrentWorkTripCompensation(currentRoadCompensationId)
                // Reset form
                setCurrentRoadCompensationId("")
                setNameOfExpense("");
                setCostOfExpense("");
                setFormValid(false);

            })
            .catch(error => {
                console.error('Error submitting expense:', error);
                setErrorMessage("Nastala chyba zkuste to prosím znovu.");
            });
    };

    const fetchVillages = () => {
        if (authority === "HEADQUARTERS") {
            request = "/towns";
        } else {
            request = "/rc/" + RCid + "/towns";
        }
        if (localStorage.getItem("towns") === null) {
            fetch(process.env.REACT_APP_API_URL + request, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setVillages(jsonData);
                    localStorage.setItem("towns", JSON.stringify(jsonData))
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } else {
            setVillages(JSON.parse(localStorage.getItem("towns")))
        }
    };

    const [documentIsLoading, setDocumentIsLoading] = useState(false);

    function calculateCompensation() {
        const fuelPrice = 38.2
        const usageCompensation = 5.6

        return (roadDistance * usageCompensation + roadDistance * (fuelPrice * fuelConsumption / 100)).toFixed(2)
    }

    useEffect(() => {
        checkErrorsTimesheet()
    }, [transportType, roadCompensationDate, roadCompensationStartTime, roadCompensationEndTime, roadCompensationDescription, selectedProjectId, selectedVillageId,
        selectedPreProjectConsultationId, selectedCarId,
        fromPlace, toPlace, roadDistance, roadCompensationDuration, workReportType])


    useEffect(() => {
        if (transportType === "2" || transportType === 2) {
            fetchPrivateCars();
        } else if (transportType === "3" || transportType === 3) {
            fetchCarsSFPI();
        }
    }, [transportType]);

    useEffect(() => {
        if (workReportType === "2" || workReportType === 2) {
            fetchVillages();
        } else if (workReportType === "3" || workReportType === 3) {
            fetchProjects();
        }
        else if (workReportType === "7" || workReportType === 7) {
            fetchPreProjectsConsultations();
        }
    }, [workReportType]);


    const [activityCollision, setActivityCollision] = useState(false)
    const [drivingLicenceInvalid, setDrivingLicenceInvalid] = useState(false)

    function checkErrorsTimesheet() {
        const formStartTime = new Date(`${roadCompensationDate}T${roadCompensationStartTime}:00`);
        const formEndTime = new Date(`${roadCompensationDate}T${roadCompensationEndTime}:00`);
        let hasMatchingActivity
        if (props.roadCompensations) {
            hasMatchingActivity = props.roadCompensations.some(item => {
                const itemStartTime = new Date(item.workReport.start);
                const itemEndTime = new Date(item.workReport.end);
                return item.workReport.id !== currentRoadCompensationId &&
                    (itemEndTime > formStartTime && itemStartTime < formEndTime);
            });
        }
        if (hasMatchingActivity) {
            setActivityCollision(true);
        } else {
            setActivityCollision(false);
        }

        let doesntHaveDrivingLicence
        if (userDetail && (transportType=== "2" || transportType === 2)) {
            if (userDetail.driversLicenceFront === null || userDetail.driversLicenceBack === null) {
                doesntHaveDrivingLicence = true
                setDrivingLicenceInvalid(true)
            }
        }
        else {
            doesntHaveDrivingLicence = false
            setDrivingLicenceInvalid(false)
        }
        let ready = !timesheetTypeInvalid && !roadCompensationDateInvalid
            && !roadCompensationStartTimeInvalid && !roadCompensationEndTimeInvalid && !roadCompensationDescriptionInvalid
            && !roadCompensationTimesRangeInvalid && !isSelectedCarInvalid && !roadCompensationFromPlaceInvalid && !roadCompensationToPlaceInvalid
            && !passengerInvalid
            && !doesntHaveDrivingLicence && !userDetailsInvalid
            && !hasMatchingActivity
        console.log(timesheetTypeInvalid, roadCompensationDateInvalid, roadCompensationStartTimeInvalid, roadCompensationEndTimeInvalid, roadCompensationDescriptionInvalid)
        console.log(ready)
        if (workReportType === "2") {
            ready = ready && !selectedVillageIdInvalid
        } else if (workReportType === "3") {
            ready = ready && !selectedProjectIdInvalid
        } else if (workReportType === "7") {
            ready = ready && !selectedPreProjectConsultationIdInvalid
        }
        console.log(ready)
        if (transportType === "2") {
            ready = ready && !roadCompensationDistanceInvalid
        }
        console.log(ready)
        setRoadCompensationValid(ready)
    }


    const validateForm = () => {
        if (nameOfExpense && costOfExpense) {
            setFormValid(true);
            setErrorMessage("");
        } else {
            setFormValid(false);
            setErrorMessage("Prosím vyplňte všechny povinné položky.");
        }
    };
    useEffect(() => {
        validateForm();
    }, [nameOfExpense, costOfExpense]);

    function uploadDocument(file, expenseId) {
        setDocumentIsLoading(true)
        const formData = new FormData();
        formData.append('file', file);


        fetch(`${process.env.REACT_APP_API_URL}/workTrips/${currentRoadCompensationId}/expenses/${expenseId}/invoice/upload`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setDocumentIsLoading(false)
                console.log(jsonData);
                fetchCurrentWorkTripCompensation(currentRoadCompensationId)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    function fetchExpenseDocument(id, name) {
        fetch(`${process.env.REACT_APP_API_URL}/workTrips/${currentRoadCompensationId}/expenses/${id}/invoice/download`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                // Check if the MIME type of the blob is a PDF or CSV
                if (blob.type === 'application/pdf') {
                    // Open the file in a new tab
                    window.open(url);
                } else {
                    // Download the file
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            })

    }


    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <GovIcon type="basic" name="chevron-down"></GovIcon>
            </components.DropdownIndicator>
        );
    };

    const handleFromPlaceChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationFromPlaceInvalid(true)
        } else {
            setRoadCompensationFromPlaceInvalid(false)
        }
        setFromPlace(event.target.value);
    }

    const handleFoodCountProvidedChange = (event) => {
        if (event.target.value === "") {
            setFoodCountProvidedInvalid(true)
        } else {
            setFoodCountProvidedInvalid(false)
        }
        setFoodCountProvided(event.target.value);
    }

    const handleToPlaceChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationToPlaceInvalid(true)
        } else {
            setRoadCompensationToPlaceInvalid(false)
        }
        setToPlace(event.target.value);
    }

    const handleRoadDistanceChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationDistanceInvalid(true)
        } else {
            setRoadCompensationDistanceInvalid(false)
        }
        setRoadDistance(event.target.value)
    }

    const handleStartTimeChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationStartTimeInvalid(true)
        } else {
            setRoadCompensationStartTimeInvalid(false)
        }
        setRoadCompensationStartTime(event.target.value);
        setRoadCompensationDuration(calculateRoadTripDuration(event.target.value, roadCompensationEndTime));
    }

    const handleEndTimeChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationEndTimeInvalid(true)
        } else {
            setRoadCompensationEndTimeInvalid(false)
        }
        setRoadCompensationEndTime(event.target.value);
        setRoadCompensationDuration(calculateRoadTripDuration(roadCompensationStartTime, event.target.value));
    }

    const handleDateChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationDateInvalid(true)
        } else {
            setRoadCompensationDateInvalid(false)
        }
        setRoadCompensationDate(event.target.value);
        setRoadCompensationDuration(calculateRoadTripDuration(roadCompensationStartTime, roadCompensationEndTime));
    };

    const customStyles = {
        dropdownIndicator: (styles, state) => ({
            ...styles,
            width: "28px",
            marginRight: "5px",
            "&:hover": {
                color: "#686868"
            },
            color: state.isFocused ? "#686868" : "#686868",
            fill: "red"
        }),
        option: (styles, state) => ({
            ...styles,
            cursor: 'pointer',
        }),
        control: (provided, state) => ({
            ...provided,
            background: '#fff',
            cursor: 'pointer',
            borderRadius: '3px',
            "&:hover": {
                borderColor: "#2362a2"
            },
            borderColor: '#686868',
            minHeight: '32px',
            height: '32px',
            boxShadow: state.isFocused ? null : null,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            color: '#686868',
        }),

        valueContainer: (provided, state) => ({
            ...provided,
            height: '30px',
            fontSize: '14px',
            padding: '0 8px',
        }),

        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            color: '#686868',
        }),
        indicatorSeparator: state => ({
            display: 'none',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: '#686868',
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: '32px',
        }),
    };

    const handleMeanOfTransportSelectChange = (event) => {
        setSelectedCarId("")
        if (event.target.value === "4") {
            setPassengerInvalid(true)
            setRoadCompensationDescription("Spolujezdec: ")
        } else {
            setPassengerInvalid(false)
            setRoadCompensationDescription("")
        }
        setTransportType(event.target.value);
    };

    const handlePreProjectConsultationChange = (event) => {
        if (event.target === "") {
            setSelectedPreProjectConsultationIdInvalid(true)
        } else {
            setSelectedPreProjectConsultationIdInvalid(false)
        }
        setSelectedPreProjectConsultationId(event.value);
        setRoadCompensationStartTime(convertIsoToTime(event.start))
        setRoadCompensationEndTime(convertIsoToTime(event.end))
        setRoadCompensationDate(convertIsoToLocalDate(event.start))
        setRoadCompensationStartTimeInvalid(false)
        setRoadCompensationEndTimeInvalid(false)
        setRoadCompensationDateInvalid(false)
        setRoadCompensationDuration(calculateHoursWorkedForDateChange(convertIsoToLocalDate(event.start), convertIsoToTime(event.start), convertIsoToTime(event.end)));
    };

    const handleVillageIdChange = (event) => {
        if (event.target === "") {
            setSelectedVillageIdInvalid(true)
        } else {
            setSelectedVillageIdInvalid(false)
        }
        setSelectedVillageId(event.value);
    };

    const handleDescriptionChange = (event) => {
        if (event.target.value === "") {
            setRoadCompensationDescriptionInvalid(true)
        } else {
            setRoadCompensationDescriptionInvalid(false)
        }
        if ((transportType === "4" || transportType === 4)) {
            if (event.target.value.includes("Spolujezdec:")) {
                setPassengerInvalid(false)
            } else {
                setPassengerInvalid(true)
            }
        }
        setRoadCompensationDescription(event.target.value);
    }

    const fetchCarsSFPI = () => {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/company`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setCarsSFPI(jsonData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    };

    function fetchPrivateCars() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private?userId=${user.id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setCarsArray(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const [isSelectedCarInvalid, setIsSelectedCarInvalid] = useState(false);
    const handleCarIdChange = (event) => {
        if (transportType === "2" || transportType === 2) {
            const selectedCar = cars.find(car => car.id.toString() === event.target.value);
            setIsSelectedCarInvalid(selectedCar
                ? [selectedCar.crashInsurance, selectedCar.carInsurance, selectedCar.technicalCertificate, selectedCar.greenCard].some(field => field === null)
                : false);
        } else {
            setIsSelectedCarInvalid(false)
        }
        setSelectedCarId(event.target.value);
    };
    useEffect(() => {
        return () => {
            document.body.classList.remove('body-fixed');
        };
    }, []);

    function goToCarAddPage() {
        navigate("/profile/cars/add")
    }

    function goToCarDetailPage(id) {
        navigate(`/profile/cars/${id}`)
    }

    function goToUserDetailPage() {
        navigate(`/profile/edit/${user.id}`)
    }

    const handleWorkReportTypeChange = (event) => {
        setRoadCompensationDuration(null)
        setRoadCompensationStartTime('')
        setRoadCompensationEndTime('')
        setRoadCompensationDate('2024-01-01')
        setRoadCompensationStartTimeInvalid(true)
        setRoadCompensationEndTimeInvalid(true)
        setRoadCompensationDateInvalid(true)
        setWorkReportType(event.target.value);
    }
    const handleProjectIdChange = (event) => {
        if (event.value === "") {
            setSelectedProjectIdInvalid(true)
        } else {
            setSelectedProjectIdInvalid(false)
        }
        setSelectedProjectId(event.value);
    };

    let request
    const fetchPreProjectsConsultations = () => {
        if (authority !== "HEADQUARTERS") {
            request = "?rcId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/preProjectConsultations" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const preProjectConsultationsArray = jsonData.map(preProjectConsultation => {
                    return {value: preProjectConsultation.id, label: preProjectConsultation.town.name + " (" + preProjectConsultation.id + ")", start: preProjectConsultation.start, end: preProjectConsultation.end}
                })
                console.log(preProjectConsultationsArray)
                setPreProjectConsultations(preProjectConsultationsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };
    const fetchProjects = () => {
        if (authority !== "HEADQUARTERS") {
            request = "?regionalCenterId=" + RCid;
        } else {
            request = "";
        }
        fetch(process.env.REACT_APP_API_URL + "/projects" + request, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                const projectsArray = jsonData.map(project => {
                    return {value: project.id, label: project.name + " (" + project.id + ")"}
                })
                setProjects(projectsArray);

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        if (!props.create) {
            setCurrentRoadCompensationId(props.id)
            fetchCurrentWorkTripCompensation(props.id)
        }
    }, []);

    const [addExpensesAfterCreation, setAddExpensesAfterCreation] = useState(false)

    function openRoadCompensationModalForAdditionalExpenses(id) {
        props.setCreateTimesheet(false)
        fetchCurrentWorkTripCompensation(id)
        setPageView("Další náklady")
        setAddExpensesAfterCreation(true)
    }

    const [userDetail, setUserDetail] = useState(null)
    useEffect(() => {
        fetchUser()
    }, [])

    useEffect(() => {
        checkErrorsTimesheet()
    }, [userDetail]);
    const [userDetailsInvalid, setUserDetailsInvalid] = useState(false)

    function fetchUser() {
        fetch(process.env.REACT_APP_API_URL + `/users/${user.id}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }).then(jsonData => {
            console.log(jsonData)
            setUserDetailsInvalid(
                jsonData.address === null ||
                jsonData.employeeId === null ||
                jsonData.workAgreementTypeId === null ||
                jsonData.workAllocation === null ||
                jsonData.workFrom === null ||
                jsonData.workTo === null
            );
            setUserDetail(jsonData)
        })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <>
            <GovModal className="gov-modal" open={modalOpen} onGov-close={props.onGovModalClose}
                      label={
                          (() => {
                              if (props.create) {
                                  return "Vyplnění nové cestovní náhrady";
                              } else {
                                  if (addExpensesAfterCreation) {
                                      return "Doplnit další náklady";
                                  } else {
                                      return "Úprava cestovních náhrad";
                                  }
                              }
                          })()
                      }
                      wcag-close-label="Close dialog box with more information">
                <div>
                    {!props.create &&
                        <div className="mb-10">
                            <GovTabs onGov-change={handleSettingPageView} type="chip" variant="primary"
                                     wcag-label="Basic information about the gov.cz design system">
                                <GovTabsItem label="Cesta"></GovTabsItem>
                                <GovTabsItem
                                    label="Další náklady" {...(pageView === "Další náklady" ? {default: true} : {})}/>
                            </GovTabs>
                        </div>
                    }
                </div>
                <div>
                    {pageView === "Cesta" &&
                        <div>
                            <div>
                                <div>
                                    <GovFormControl>
                                        <GovFormSelect id="dynamic-select" value={transportType}
                                                       onGov-change={handleMeanOfTransportSelectChange}
                                                       size="s" variant="secondary">
                                            <option value="1">Veřejná doprava</option>
                                            <option value="2">Soukromé vozidlo</option>
                                            <option value="3">Vozidlo SFPI</option>
                                            <option value="4">Spolujízda</option>


                                        </GovFormSelect>
                                        <GovFormLabel slot="top" size="m">Vyberte dopravní prostředek</GovFormLabel>
                                        <div className="mt-10">
                                            {drivingLicenceInvalid &&
                                                <GovMessage slot="bottom" variant="error">Uživatel nemá doplněný řidičký
                                                    průkaz.
                                                    <GovIcon type="basic" name="exclamation-triangle-fill"
                                                             slot="icon"></GovIcon>
                                                    <GovButton className="ml-5" type="link" variant="error"
                                                               size="m"
                                                               onGov-click={() => goToUserDetailPage()}>
                                                        Doplnit řidičský průkaz</GovButton>
                                                </GovMessage>

                                            }
                                        </div>
                                    </GovFormControl>
                                </div>

                                <div>
                                    {(transportType === '2' || transportType === 2) &&
                                        <div>
                                            <GovFormControl>
                                                <div>
                                                    {(cars.length !== 0) ?
                                                        <GovFormSelect value={selectedCarId}
                                                                       onGov-change={handleCarIdChange}
                                                                       size="s" variant="secondary">
                                                            <option disabled="disabled" value="">Vyberte vozidlo
                                                            </option>
                                                            {cars.length > 0 && cars.map((car) => (
                                                                <option key={car.id}
                                                                        value={car.id}>{car.name + " (" + car.licensePlate + ")"}</option>
                                                            ))
                                                            }
                                                        </GovFormSelect> :
                                                        <div>
                                                            V aktuální chvíli nemáte přidané žádné vozidlo.
                                                            <GovButton className="ml-5" type="link" variant="primary"
                                                                       size="m" onGov-click={goToCarAddPage}>Přidat
                                                                vozidlo</GovButton>
                                                        </div>
                                                    }
                                                </div>
                                                <GovFormLabel slot="top" size="m">Vyberte vozidlo</GovFormLabel>
                                                <div className="mt-10">
                                                    {isSelectedCarInvalid &&

                                                        <GovMessage slot="bottom" variant="error">Toto vozidlo nemá
                                                            doplněné povinné dokumenty.
                                                            <GovIcon type="basic" name="exclamation-triangle-fill"
                                                                     slot="icon"></GovIcon>
                                                            <GovButton className="ml-5" type="link" variant="error"
                                                                       size="m"
                                                                       onGov-click={() => goToCarDetailPage(selectedCarId)}>
                                                                Doplnit dokumenty</GovButton>
                                                        </GovMessage>
                                                    }
                                                </div>
                                            </GovFormControl>
                                        </div>
                                    }
                                    {(transportType === '3' || transportType === 3) &&
                                        <div>
                                            <GovFormControl>
                                                <div>
                                                    {(carsSFPI.length !== 0) ?
                                                        <GovFormSelect value={selectedCarId}
                                                                       onGov-change={handleCarIdChange}
                                                                       size="s" variant="secondary">
                                                            <option disabled="disabled" value="">Vozidlo SFPI</option>
                                                            {carsSFPI.length > 0 && carsSFPI.map((car) => (
                                                                <option key={car.id}
                                                                        value={car.id}>{car.name + " (" + car.licensePlate + ")"}</option>
                                                            ))
                                                            }
                                                        </GovFormSelect> :
                                                        <GovSkeleton wcag-label="Loading content of page"
                                                                     variant="secondary"
                                                                     shape="text"
                                                                     animation="progress" count="1" width="300px"
                                                                     height="30px"></GovSkeleton>
                                                    }
                                                </div>
                                                <GovFormLabel slot="top" size="m">Vyberte ID vozidla SFPI</GovFormLabel>
                                            </GovFormControl>
                                        </div>
                                    }
                                    {/*{(transportType === '4' || transportType === 4) &&*/}
                                    {/*    <div>*/}
                                    {/*        <GovFormControl>*/}
                                    {/*            <GovFormInput size="s" variant="secondary"*/}
                                    {/*                          placeholder="Spolujezdec"></GovFormInput>*/}
                                    {/*            <GovFormLabel slot="top" size="s">Spolujezdec</GovFormLabel>*/}
                                    {/*        </GovFormControl>*/}
                                    {/*    </div>*/}
                                    {/*}*/}
                                    <GovFormControl>
                                        <GovFormSelect id="dynamic-select" value={workReportType}
                                                       onGov-change={handleWorkReportTypeChange}
                                                       size="s" variant="secondary">
                                            <option value="1">Administrativa</option>
                                            <option value="2">Obec</option>
                                            <option value="3">Projekt</option>
                                            <option value="7">Konzultace</option>
                                            <option value="4">Konzultace MMR</option>
                                            <option value="5">Školení</option>
                                            <option value="6">Cesta</option>
                                        </GovFormSelect>
                                        <GovFormLabel slot="top" size="m">Upřesňující typ cesty</GovFormLabel>
                                    </GovFormControl>

                                    <div>
                                        {(workReportType === "3" || workReportType === 3) &&
                                            <GovFormControl>
                                                <div>
                                                    {projects.length !== 0 ?
                                                        <Select options={projects} onChange={handleProjectIdChange}
                                                                placeholder={"Id projektu"}
                                                                value={projects.find(project => project.value === selectedProjectId)}
                                                                components={{DropdownIndicator}}
                                                                styles={customStyles}
                                                                noOptionsMessage={() => "Nenašli jsme projekty"}

                                                        />
                                                        : <GovSkeleton wcag-label="Loading content of page"
                                                                       variant="secondary"
                                                                       shape="text"
                                                                       animation="progress" count="1" width="300px"
                                                                       height="30px"></GovSkeleton>
                                                    }
                                                </div>
                                                <GovFormLabel slot="top" size="m">Vyberte ID projektu, ke kterému se
                                                    cesta
                                                    vztahuje</GovFormLabel>
                                            </GovFormControl>
                                        }
                                    </div>
                                    <div>
                                        {(workReportType === "2" || workReportType === 2) &&

                                            <GovFormControl>
                                                <div>
                                                    {villages.length !== 0 ?
                                                        <Select components={{MenuList, DropdownIndicator}}
                                                                options={villages.map(village => {
                                                                    return {
                                                                        value: village.id,
                                                                        label: village.name + " (" + village.id + ")"
                                                                    }
                                                                })} onChange={handleVillageIdChange}
                                                                placeholder={"Id obce"}
                                                                value={villages.map(village => {
                                                                    return {
                                                                        value: village.id,
                                                                        label: village.name + " (" + village.id + ")"
                                                                    }
                                                                }).find(village => village.value === selectedVillageId)}
                                                                styles={customStyles}
                                                                noOptionsMessage={() => "Nenašli jsme žádné obce"}/>
                                                        : <GovSkeleton wcag-label="Loading content of page"
                                                                       variant="secondary"
                                                                       shape="text"
                                                                       animation="progress" count="1" width="300px"
                                                                       height="30px"></GovSkeleton>
                                                    }
                                                </div>
                                                <GovFormLabel slot="top" size="m">Vyberte ID obce, ke které se cesta
                                                    vztahuje</GovFormLabel>
                                            </GovFormControl>
                                        }
                                    </div>
                                    <div>
                                        {(workReportType === "7" || workReportType === 7) &&

                                            <GovFormControl>
                                                <div>
                                                    {preProjectConsultations.length !== 0 ?
                                                        <Select options={preProjectConsultations}
                                                                onChange={handlePreProjectConsultationChange}
                                                                placeholder={"Id konzultace"}
                                                                value={preProjectConsultations.find(preProjectConsultation => preProjectConsultation.value === selectedPreProjectConsultationId)}
                                                                components={{DropdownIndicator}}
                                                                styles={customStyles}
                                                                noOptionsMessage={() => "Nenašli jsme konzultace"}

                                                        />
                                                        // <Select options={villages.map(village => {
                                                        //     return {value: village.id, label: village.name + " (" + village.id + ")"}
                                                        // })} onChange={handleVillageIdChange}
                                                        //         placeholder={"Id obce"}
                                                        //         value={villages.map(village => {
                                                        //             return {value: village.id, label: village.name + " (" + village.id + ")"}
                                                        //         }).find(village => village.value === selectedVillageId)}
                                                        //         components={{DropdownIndicator}}
                                                        //         styles={customStyles}
                                                        //         noOptionsMessage={() => "Nenašli jsme žádné obce"}
                                                        //
                                                        // />
                                                        : <GovSkeleton wcag-label="Loading content of page"
                                                                       variant="secondary"
                                                                       shape="text"
                                                                       animation="progress" count="1" width="300px"
                                                                       height="30px"></GovSkeleton>
                                                    }
                                                </div>
                                                <GovFormLabel slot="top" size="m">Vyberte ID konzultace, ke které se
                                                    cesta
                                                    vztahuje</GovFormLabel>
                                            </GovFormControl>
                                        }
                                    </div>


                                    <GovFormControl>
                                        <GovFormInput size="s" variant="secondary"
                                                      placeholder="Odkud" value={fromPlace}
                                                      onGov-input={handleFromPlaceChange}></GovFormInput>
                                        <GovFormLabel slot="top" size="s">Odkud</GovFormLabel>
                                    </GovFormControl>
                                    <GovFormControl>
                                        <GovFormInput size="s" variant="secondary"
                                                      placeholder="Kam" value={toPlace}
                                                      onGov-input={handleToPlaceChange}></GovFormInput>
                                        <GovFormLabel slot="top" size="s">Kam</GovFormLabel>
                                    </GovFormControl>
                                    {(transportType === '2' || transportType === 2) &&
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          placeholder="Délka cesty v km" input-type="number"
                                                          value={roadDistance}
                                                          onGov-input={handleRoadDistanceChange}></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Délka cesty v km</GovFormLabel>
                                        </GovFormControl>
                                    }
                                    <GovFormControl>
                                        <GovFormLabel slot="top" size="s">Datum cesty</GovFormLabel>
                                        <GovFormInput
                                            size="s" variant="secondary"
                                            input-type="date"
                                            value={roadCompensationDate}
                                            min="2024-01-01"
                                            max={new Date().toISOString().split('T')[0]}
                                            onGov-input={handleDateChange}>
                                        </GovFormInput>
                                    </GovFormControl>
                                    <GovFormControl>
                                        <GovFormLabel slot="top" size="s">Začátek cesty</GovFormLabel>
                                        <GovFormInput
                                            size="s" variant="secondary"
                                            input-type="time"
                                            value={roadCompensationStartTime}
                                            onGov-input={handleStartTimeChange}>
                                        </GovFormInput>
                                    </GovFormControl>
                                    <GovFormControl>
                                        <GovFormLabel slot="top" size="s">Konec cesty</GovFormLabel>
                                        <GovFormInput
                                            size="s" variant="secondary"
                                            input-type="time"
                                            value={roadCompensationEndTime}
                                            onGov-input={handleEndTimeChange}>
                                        </GovFormInput>
                                    </GovFormControl>
                                    <div>
                                        {activityCollision &&
                                            <GovMessage variant="error">
                                                <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                                <p>V tomto čase již existuje jiná cesta.</p>
                                            </GovMessage>
                                        }
                                    </div>
                                    <GovFormControl>
                                        <GovFormInput size="s" variant="secondary"
                                                      disabled="disabled"
                                                      value={roadCompensationDuration}
                                                      min="0"
                                                      placeholder="Počet hodin"></GovFormInput>
                                        <GovFormLabel slot="top" size="s">Počet hodin</GovFormLabel>
                                    </GovFormControl>
                                    <GovFormControl>
                                        <GovFormSelect size="s" variant="secondary" defaultValue={0}
                                                       value={foodCountProvided}
                                                       onGov-change={handleFoodCountProvidedChange}>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                        </GovFormSelect>
                                        <GovFormLabel slot="top" size="s">Počet poskytnutých jídel</GovFormLabel>
                                    </GovFormControl>
                                    <GovFormControl>
                                        <GovFormGroup>
                                            <GovFormInput size="m" variant="secondary" input-type="text"
                                                          multiline="true"
                                                          placeholder="Důvod/program cesty"
                                                          rows="6"
                                                          value={roadCompensationDescription}
                                                          onGov-input={handleDescriptionChange}></GovFormInput>
                                        </GovFormGroup>
                                        <GovFormLabel slot="top" size="m">Důvod/program cesty</GovFormLabel>
                                    </GovFormControl>
                                </div>
                                <div>
                                    {passengerInvalid &&
                                        <GovMessage variant="error">
                                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                            <p>Je vybrán dopravní prostředek spolujízda, popis musí obsahovat
                                                spolujezdce v následujícím formátu: Spolujezdec: [Jméno a příjmení
                                                spolujezdce/ů]. </p>
                                        </GovMessage>
                                    }
                                </div>
                                <div>
                                    {userDetailsInvalid &&
                                        <GovMessage variant="error">
                                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                            <p>Uživatel nemá doplněné povinné údaje pro vyplnění timesheetu. Je nutné
                                                vyplnit všechny osobní údaje.
                                                <GovButton className="ml-5" type="link" variant="error"
                                                           size="m"
                                                           onGov-click={() => goToUserDetailPage()}>
                                                    Doplnit osobní údaje</GovButton></p>

                                        </GovMessage>

                                    }
                                </div>
                                <div>
                                    {roadCompensationTimesRangeInvalid &&
                                        <GovMessage variant="error">
                                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                            <p>Začátek pracovní doby nesmí být stejný jako konec, případně pak začátek
                                                pracovní doby musí
                                                předcházet konci pracovní doby.
                                                Rozdíl počtu minut mezi začátkem a koncem cesty musí být násobkem
                                                15. </p>
                                        </GovMessage>
                                    }
                                </div>
                                <div>
                                    {!roadCompensationValid &&
                                        <GovMessage variant="error">
                                            <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                            <p>Nejsou vyplněny všechny povinné položky</p>
                                        </GovMessage>
                                    }
                                </div>

                                <GovButton variant="primary" size="m" type="solid"
                                           wcag-label="Open dialog box for more information"
                                           disabled={!roadCompensationValid}
                                           onGov-click={props.create ? postRoadCompensation : putRoadCompensation}
                                >
                                    <div>
                                        {props.create ? "Přidat" : "Upravit"}
                                    </div>
                                </GovButton>
                            </div>

                        </div>
                    }
                    <div>
                        {pageView === "Další náklady" &&
                            <div>
                                <GovFormControl>
                                    <GovFormInput value={nameOfExpense} onGov-input={handleNameChange} size="s"
                                                  variant="secondary" input-type="text"
                                                  placeholder="Název nákladu"></GovFormInput>
                                    <GovFormLabel slot="top" size="s">Název nákladu</GovFormLabel>
                                </GovFormControl>
                                <GovFormControl>
                                    <GovFormInput value={costOfExpense} onGov-input={handleCostChange} size="s"
                                                  variant="secondary" input-type="number"
                                                  placeholder="Cena (vč. DPH)"></GovFormInput>
                                    <GovFormLabel slot="top" size="s">Cena (vč. DPH)</GovFormLabel>
                                </GovFormControl>
                                {((transportType === '1' || transportType === 1) && addExpensesAfterCreation) &&
                                    <GovMessage variant="error">
                                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                        <p>Vybrali jste dopravní prostředek "Veřejná doprava" doplňte prosím další
                                            náklady. </p>
                                    </GovMessage>
                                }
                                {errorMessage && <GovMessage variant="error"><p>{errorMessage}</p></GovMessage>}
                                <GovButton variant="primary" size="m" type="solid" wcag-label="Uložit"
                                           disabled={!formValid} onGov-click={postWorkTripExpense}>
                                    Uložit další náklad
                                </GovButton>
                            </div>

                        }
                        {pageView === "Další náklady" &&
                            <div>
                                {expenses.length !== 0 ? (
                                    <table>
                                        <thead>
                                        <tr>
                                            <th>Název</th>
                                            <th>Cena</th>
                                            <th>Doklad o zaplacení</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {expenses.map((expense, index) => (
                                            <tr key={index}>
                                                <td>{expense.name}</td>
                                                <td>{expense.cost} Kč</td>
                                                <td>{expense.invoice && <div>
                                                    <FontAwesomeIcon icon={getIcon(expense.invoice.name)}/>
                                                    <span> </span>
                                                    <a href={expense.invoice.link} onClick={(event) => {
                                                        event.preventDefault();
                                                        fetchExpenseDocument(expense.id, expense.invoice.name);
                                                    }}>
                                                        {expense.invoice.name}
                                                    </a>
                                                </div>}</td>
                                                <td>
                                                    <GovFormFile
                                                        onGov-add-file={(event) => uploadDocument(event.detail.file.file, expense.id)}
                                                        max-file-size="616448"
                                                        accept=".pdf,.doc,.docx"
                                                        expanded="false"
                                                        displayAttachments="false">
                                                        <GovButton variant="solid" type="link" size="s"
                                                        >Nahrát soubor ze
                                                            zařízení
                                                        </GovButton>

                                                    </GovFormFile>

                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                ) : (
                                    <p>Žádné další náklady</p>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </GovModal>
            {documentIsLoading &&
                <div>
                    <GovLoading>Prosím vyčkejte soubor se nahrává</GovLoading>
                </div>
            }
        </>
    )
}
