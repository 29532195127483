import {Header} from "../components/Header";
import {SettingNewPasswordContent} from "../components/contentComponents/SettingNewPasswordContent";
import {Footer} from "../components/Footer";
import {SyncConsultationsContent} from "../components/contentComponents/SyncConsulatationsContent";
import {MMRExportContent} from "../components/contentComponents/MMRExportContent";

export function MMRExport() {
    return (
        <div>
            <Header></Header>
            <MMRExportContent></MMRExportContent>
            <Footer></Footer>
        </div>
    )
}
