import {useEffect, useState} from "react";
import Navigation from "../Navigation";
import {
    GovBreadcrumbs, GovButton,
    GovFormControl,
    GovFormInput, GovFormLabel, GovFormSelect,
    GovIcon,
    GovModal,
    GovTile,
    GovTiles
} from "@gov-design-system-ce/react";
import {fuelTypeToText, parseJwt} from "../utils/utils";

export function UsersCarsPageContent() {
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")

    let [cars, setCarsArray] = useState([])
    let user = JSON.parse(localStorage.getItem("user"));
    let jwt = localStorage.getItem("jwt");
    const authority = parseJwt(jwt).role[0].authority;
    const RCid = localStorage.getItem("RCid");
    const regionalCentre = localStorage.getItem("RCname");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let request = ""
    if (urlParams.get("rcId") && urlParams.get("rcId") === "0" && authority === "HEADQUARTERS") {
        request = "";
    } else if (urlParams.get("rcId") || authority !== "HEADQUARTERS") {
        request = "?rcId=" + (urlParams.get("rcId") ? urlParams.get("rcId") : RCid);
    }
    function fetchPrivateCars() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private${request}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    console.log(jsonData)
                    setCarsArray(jsonData);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        fetchPrivateCars()
    }, [])

    const allRegionalCenters = [
        "Moravskoslezský kraj",
        "Středočeský kraj a Praha",
        "Ústecký a Karlovarský kraj",
        "Královehradecký a Liberecký kraj",
        "Pardubický kraj a Kraj Vysočina",
        "Jihočeský a Plzeňský kraj",
        "Zlínský a Jihomoravský kraj",
        "Olomoucký kraj"
    ];
    const [currentCarId, setCurrentCarId] = useState();
    const [chosenCar, setChosenCar] = useState();
    const [carEdit, setCarEdit] = useState(true);
    const [licensePlate, setLicensePlate] = useState("");
    const [regionalCenter, setRegionalCenter] = useState("");
    const [regionalCenters, setRegionalCenters] = useState(allRegionalCenters); // Assuming `allRegionalCenters` is already defined
    const [isModalOpen, setIsModalOpen] = useState(false);


    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;
        const url = new URL(window.location.href);
        url.searchParams.set('rcId', selectedValue);
        window.location.href = url;
    };

// Handlers for input changes
    const handleCarNameChange = (event) => {
        setChosenCar(event.target.value);
    };

    const handleLicensePlateChange = (event) => {
        setLicensePlate(event.target.value);
    };

    const handleRegionalCenterChange = (event) => {
        setRegionalCenter(event.target.value);
    };

// Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

// Function to close the modal
    const closeModal = () => {
        setCurrentCarId(null)
        setCarEdit(true)
        setChosenCar("");
        setLicensePlate("");
        setRegionalCenter("");
        setIsModalOpen(false);
    };

    function putPrivateCar() {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private/${currentCarId}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${jwt}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    "name": chosenCar,
                    "licensePlate": licensePlate,
                    "regionalCenterId": regionalCenter
                }
            )
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                console.log("Car updated successfully");
                fetchPrivateCars()
                closeModal();
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })
            .catch(error => {
                return error.message;
            });
    }

    function setCurrentCar(carId) {
        setCarEdit(false)
        fetchPrivateCar(carId)
        openModal()
    }

    function fetchPrivateCar(carId) {
        fetch(process.env.REACT_APP_API_URL + `/vehicles/private/${carId}`, {
            headers: {
                'Authorization': `Bearer ${jwt}`,
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                if (jsonData) {
                    setChosenCar(jsonData)
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    return (
        <div className="vh-100">
            <Navigation/>
            <div className="gov-container">
                <div className="gov-container__content">
                    <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                        <ul>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/">Úvod</a></li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/settings">Nastavení</a>
                            </li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <strong>Správa vozového parku uživatelů</strong>
                            </li>
                        </ul>
                    </GovBreadcrumbs>
                </div>
                <div className="gov-container">
                    <div className="gov-title--beta">Přehled
                        vozidel zaměstnanců{authority === "HEADQUARTERS" ? "" : ": " + regionalCentre}</div>

                    { authority === "HEADQUARTERS" &&
                        <div className="gov-flexbox search-row-width">
                            <GovFormControl>
                                <GovFormSelect size="m" variant="secondary"
                                               value={urlParams.get("rcId") ? urlParams.get("rcId") : ""}
                                               onGov-change={handleSelectChange}>
                                    <option disabled="disabled" value="">Regionální centrum</option>
                                    <option value="0">Všechna regionální centra</option>
                                    <option value="1">Moravskoslezský kraj</option>
                                    <option value="2">Středočeský kraj a Praha</option>
                                    <option value="3">Ústecký a Karlovarský kraj</option>
                                    <option value="4">Královehradecký a Liberecký kraj</option>
                                    <option value="5">Pardubický kraj a kraj Vysočina</option>
                                    <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                    <option value="7">Zlínský a Jihomoravský kraj</option>
                                    <option value="8">Olomoucký kraj</option>
                                </GovFormSelect>
                            </GovFormControl>
                        </div>
                    }
                    {cars.length !== 0 ? (
                        <GovTiles columns="2">
                            {cars.map((row, index) => (
                                <GovTile key={index} href="#" onClick={() => setCurrentCar(row.id)}>
                                    <p>Zobrazit detail vozidla.</p>
                                    <h3 slot="title">{row.user.firstname[0] + ". " + row.user.lastname} - {row.name} ({row.licensePlate})</h3>
                                    <GovIcon type="basic" name="car" slot="icon"></GovIcon>
                                </GovTile>
                            ))}
                        </GovTiles>
                    ) : (
                        <div>
                            <p>Žádná vozidla nebyla nalezena.</p>
                        </div>
                    )}
                    {chosenCar &&
                        <GovModal open={isModalOpen} onGov-close={closeModal} label={chosenCar.name}
                                  wcag-close-label="Close dialog box with more information">
                            <div>
                                <h4>Majitel vozidla</h4>
                                <p>{chosenCar.user.firstname + " " + chosenCar.user.lastname}</p>
                                <h4>Název vozidla</h4>
                                <p>{chosenCar.name}</p>
                                <h4>SPZ</h4>
                                <p>{chosenCar.licensePlate}</p>
                                <h4>Typ paliva</h4>
                                <p>{fuelTypeToText(chosenCar.fuelTypeId)}</p>
                                <h4>Kombinovaná spotřeba</h4>
                                <p>{chosenCar.consumption}</p>
                                <div className="document-grid">
                                    <div className="document-item">
                                        <div className={chosenCar.carInsurance ? 'has-file' : 'no-file'}>
                                            {chosenCar.carInsurance ? (
                                                <GovIcon type="basic" name="check-lg"/>
                                            ) : (
                                                <GovIcon type="basic" name="exclamation-circle"/>
                                            )}
                                        </div>
                                        <span className={`ml-5 ${chosenCar.carInsurance ? 'has-file' : 'no-file'}`}>
                                            Povinné ručení
                                        </span>
                                    </div>
                                    <div className="document-item">
                                        <div className={chosenCar.crashInsurance ? 'has-file' : 'no-file'}>
                                            {chosenCar.crashInsurance ? (
                                                <GovIcon type="basic" name="check-lg"/>
                                            ) : (
                                                <GovIcon type="basic" name="exclamation-circle"/>
                                            )}
                                        </div>
                                        <span className={`ml-5 ${chosenCar.crashInsurance ? 'has-file' : 'no-file'}`}>
                                            Havarijní pojištění
                                        </span>
                                    </div>
                                    <div className="document-item">
                                        <div className={chosenCar.greenCard ? 'has-file' : 'no-file'}>
                                            {chosenCar.greenCard ? (
                                                <GovIcon type="basic" name="check-lg"/>
                                            ) : (
                                                <GovIcon type="basic" name="exclamation-circle"/>
                                            )}
                                        </div>
                                        <span className={`ml-5 ${chosenCar.greenCard ? 'has-file' : 'no-file'}`}>
                                            Zelená karta
                                        </span>
                                    </div>
                                    <div className="document-item">
                                        <div className={chosenCar.technicalCertificate ? 'has-file' : 'no-file'}>
                                            {chosenCar.technicalCertificate ? (
                                                <GovIcon type="basic" name="check-lg"/>
                                            ) : (
                                                <GovIcon type="basic" name="exclamation-circle"/>
                                            )}
                                        </div>
                                        <span
                                            className={`ml-5 ${chosenCar.technicalCertificate ? 'has-file' : 'no-file'}`}>
                                            Technický průkaz
                                        </span>
                                    </div>
                                </div>
                                <GovButton variant="primary" size="m" type="link" wcag-label="Add new car"
                                           href={`/profile/cars/${chosenCar.id}`}>
                                    Upravit
                                </GovButton>
                            </div>
                        </GovModal>
                    }
                </div>
            </div>
        </div>
    )
}
