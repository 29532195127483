import {HomeContent} from "../components/contentComponents/HomeContent";
import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {parseJwt} from "../components/utils/utils";
import {useEffect, useState} from "react";

export function Home() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const jwt = localStorage.getItem("jwt");
        if(jwt){
            const userDetails = parseJwt(jwt);
            setUser({
                role: userDetails.role[0].authority
            });
        }
    }, []);
    function onLogout(){
        setUser(null)
    }
    return (
        <div>
            <Header onLogout={onLogout}></Header>
            <HomeContent user={user}></HomeContent>
            <Footer></Footer>
        </div>
    )
}
