import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {ProjectDetailContent} from "../components/contentComponents/ProjectDetailContent";


export function ProjectDetail() {
    return (
        <div>
            <Header></Header>
            <ProjectDetailContent></ProjectDetailContent>
            <Footer></Footer>
        </div>
    )
}
