import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {useParams} from "react-router-dom";

import {MonthReportManagementContent} from "../components/contentComponents/MonthReportManagementContent";

export function MonthReportManagement() {
    return (
        <div>
            <Header></Header>
            <MonthReportManagementContent></MonthReportManagementContent>
            <Footer></Footer>
        </div>
    )
}
