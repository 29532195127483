import "../css/layouts.css";
import Navigation from "../Navigation";
import {
    GovAccordionItem,
    GovBreadcrumbs,
    GovButton, GovFormCheckbox, GovFormControl, GovFormGroup,
    GovFormInput, GovFormLabel, GovFormMessage, GovFormMultiSelect, GovFormRadio, GovFormSelect,
    GovIcon, GovMessage, GovModal, GovPrompt, GovSkeleton
} from "@gov-design-system-ce/react";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {parse} from "svelte/compiler";

export function NewConsultationContent() {
    const { townId } = useParams();
    const jwt = localStorage.getItem('jwt');
    const [town, setTown] = useState({});
    const [rcPeople, setRcPeople] = useState([]);
    const [externalPersons, setExternalPersons] = useState([]);

    const [startDateInvalid, setStartDateInvalid] = useState(true)
    const [endDateInvalid, setEndDateInvalid] = useState(true)
    const [agreedPointsInvalid, setAgreedPointsInvalid] = useState(true)
    const [townPersonCheckedArrayInvalid, setTownPersonCheckedArrayInvalid] = useState(true)
    const [selectedExternalPersonsInvalid, setExternalSelectedPersonsInvalid] = useState(true)
    const [nextStepsInvalid, setNextStepsInvalid] = useState(true)
    const [openPointsInvalid, setOpenPointsInvalid] = useState(true)
    const [ratingInvalid, setRatingInvalid] = useState(true)
    const [resultInvalid, setResultInvalid] = useState(true)

    const collective = townId.length > 6;

    function checkErrors() {
        let ready = !startDateInvalid && !endDateInvalid && !agreedPointsInvalid && !townPersonCheckedArrayInvalid && !selectedExternalPersonsInvalid && !ratingInvalid && !resultInvalid
        setConsultationValid(ready)
        console.log([startDateInvalid, endDateInvalid, agreedPointsInvalid, townPersonCheckedArrayInvalid, selectedExternalPersonsInvalid, ratingInvalid, resultInvalid])
    }

    useEffect(() => {
        checkErrors()
    }, [startDateInvalid, endDateInvalid, agreedPointsInvalid, townPersonCheckedArrayInvalid, selectedExternalPersonsInvalid, ratingInvalid, resultInvalid]);

    const [consultationValid, setConsultationValid] = useState(false)

    function fetchExternalPeople() {
        fetch(process.env.REACT_APP_API_URL+"/externalPersons", {
            headers: {
                'Authorization': `Bearer ${jwt}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(jsonData => {
                setExternalPersons(jsonData)
                console.log(jsonData)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        const fetchData = () => {
            fetch(process.env.REACT_APP_API_URL+`/towns/${townId.length > 6 ? "collective/" : ""}`+ townId, {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setTown(jsonData)
                    fetchPeopleData(jsonData.regionalCenter.id)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };
        const fetchPeopleData = (regionalCenterId) => {
            fetch(process.env.REACT_APP_API_URL+"/rc/"+ regionalCenterId +"/employees", {
                headers: {
                    'Authorization': `Bearer ${jwt}`
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(jsonData => {
                    setRcPeople(jsonData)
                    console.log(jsonData)
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchExternalPeople()
        fetchData();
    }, []);

    function postNewConsultation(townId) {
        if (consultationValid) {
            return fetch(process.env.REACT_APP_API_URL + "/town/" + townId + "/preProjectConsultations", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${jwt}`
                },
                body: JSON.stringify({
                    "townId": parseInt(townId),
                    "start": startOfConsultation,
                    "end": endOfConsultation,
                    "agreedPoints": agreedPoints,
                    "actionPlan": nextSteps,
                    "openPoints": openPoints,
                    "rating": rating,
                    "result": resultOfConsultation,
                    "regionalCenterParticipants": townPersonCheckedArray,
                    "externalParticipants": selectedExternalPersons
                })
            })
                .then(response => {
                    response.json()
                });
        }
    }
    function postNewExternalPerson() {
        return fetch(process.env.REACT_APP_API_URL+ "/externalPersons", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwt}`
            },
            body: JSON.stringify({
                "firstname": personName,
                "lastname": personSurname,
                "roleDescription": personRoleDescription,
                "institution": personOrganization,
                "role": personRole,
                "email": personEmail,
                "phone": personTelephone
            })
        })
            .then(response => {
                if (response.ok) {
                    return 'Request successful';
                } else {
                    throw new Error('Request failed');
                }
            })
            .catch(error => {
                return error.message;
            });
    }


    const [townPersonCheckedArray, setTownPersonCheckedArray] = useState([]);

    const handleTownPersonChange = (id) => {
        let newArray = [...townPersonCheckedArray];

        if (newArray.includes(id)) {

            newArray = newArray.filter(e => e !== id);
        } else {

            newArray.push(id);
        }
        newArray.length === 0 ? setTownPersonCheckedArrayInvalid(true) : setTownPersonCheckedArrayInvalid(false)

        setTownPersonCheckedArray(newArray);
    };

    const [resultOfConsultation, setResultOfConsultation] = useState('Dohoda o spolupráci obce s RC');
    const handleRadioChange = (event) => {
        if (event.target.value === "") {
            setResultInvalid(true)
        } else {
            setResultInvalid(false)
        }
        setResultOfConsultation(event.target.value);
    }

    const [personName, setPersonName] = useState('');
    const [personSurname, setPersonSurname] = useState('');
    const [personTelephone, setPersonTelephone] = useState('');
    const [personEmail, setPersonEmail] = useState('');
    const [personRole, setPersonRole] = useState('');
    const [personOrganization, setPersonOrganization] = useState('');
    const [personRoleDescription, setPersonRoleDescription] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [startOfConsultation, setStartOfConsultation] = useState('');
    const [endOfConsultation, setEndOfConsultation] = useState('');

    const [lengthOfConsultation, setLengthOfConsultation] = useState('');
    const [openPoints, setOpenPoints] = useState('');
    const [nextSteps, setNextSteps] = useState('');
    const [agreedPoints, setAgreedPoints] = useState('');
    const [rating, setRating] = useState('');
    let navigate = useNavigate();

    const [personArray, setPersonArray]=useState([
    ])
    function addPreProjectConsultation(){
        postNewConsultation(townId).then(() => {navigate(`/towns/${townId.length > 6 ? "collective/" : ""}detail/${townId}`)
        });
    }
    function addPerson(){
        if (newPersonValid) {
            let newItem = {
                id: crypto.randomUUID(),
                name: personName,
                surname: personSurname,
                telephone: personTelephone,
                organization: personOrganization,
                email: personEmail,
                role: personRole
            }
            postNewExternalPerson().then(() => {
                fetchExternalPeople();
            });

            setPersonName("")
            setPersonSurname("")
            setPersonTelephone("")
            setPersonEmail("")
            setPersonOrganization("")
            setPersonRoleDescription("")
            setPersonRole("STATUTORY_DEPUTY")
            closeModal()
        }
    }

    const [nameInvalid, setNameInvalid] = useState(true)
    const [surnameInvalid, setSurnameInvalid] = useState(true)
    const [phoneInvalid, setPhoneInvalid] = useState(true)
    const [emailInvalid, setEmailInvalid] = useState(true)
    const [institutionInvalid, setInstitutionInvalid] = useState(true)
    const [roleInvalid, setRoleInvalid] = useState(true)

    const [smallerDate, setSmallerDate] = useState(false)

    const [newPersonValid, setNewPersonValid] = useState(false)

    function checkErrorsPerson() {
        let ready = !nameInvalid && !surnameInvalid && !phoneInvalid && !emailInvalid && !institutionInvalid && !roleInvalid
        setNewPersonValid(ready)
    }

    useEffect(() => {
        checkErrorsPerson()
    }, [nameInvalid, surnameInvalid, phoneInvalid, emailInvalid,  institutionInvalid, roleInvalid]);


    function deletePerson(uuid){
        const updatedArray = []
    }
    function openModal(){
        console.log(newPersonValid)
        setModalOpen(true)
    }
    function closeModal(){
        setModalOpen(false)
    }

    const handlePersonNameChange = (event) => {
        if (event.target.value === "") {
            setNameInvalid(true)
        } else {
            setNameInvalid(false)
        }
        setPersonName(event.target.value);
    };
    const handlePersonSurnameChange = (event) => {
        if (event.target.value === "") {
            setSurnameInvalid(true)
        } else {
            setSurnameInvalid(false)
        }
        setPersonSurname(event.target.value);
    };
    const handlePersonTelephoneChange = (event) => {
        if (!/^\d{9}$/.test(event.target.value)) {
            setPhoneInvalid(true)
        } else {
            setPhoneInvalid(false)
        }
        setPersonTelephone(event.target.value);
    };
    const handlePersonEmailChange = (event) => {
        if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value.toLowerCase())) {
            setEmailInvalid(true)
        } else {
            setEmailInvalid(false)
        }
        setPersonEmail(event.target.value);
    };
    const handlePersonOrganizationChange = (event) => {
        if (event.target.value === "") {
            setInstitutionInvalid(true)
        } else {
            setInstitutionInvalid(false)
        }
        setPersonOrganization(event.target.value);
    };
    const handlePersonRoleDescriptionChange = (event) => {
        setPersonRoleDescription(event.target.value);
    };
    const handlePersonRoleChange = (event) => {
        if (event.target.value === "") {
            setRoleInvalid(true)
        } else {
            setRoleInvalid(false)
        }
        setPersonRole(event.target.value);
    };
    const handleContactPersonChange = (event) => {
        setContactPerson(event.target.value);
    };
    const handleStartOfConsultationChange = (event) => {
        const start = new Date(event.target.value);
        const end = new Date(endOfConsultation);
        if (event.target.value === "" || start > end) {
            setStartDateInvalid(true)
        } else {
            setStartDateInvalid(false)
        }
        setStartOfConsultation(event.target.value);
    }
    const handleEndOfConsultationChange = (event) => {
        const start = new Date(startOfConsultation);
        const end = new Date(event.target.value);
        if (event.target.value === "" || start > end) {
            setEndDateInvalid(true)
        } else {
            setEndDateInvalid(false)
        }
        setEndOfConsultation(event.target.value);
    }

    const handleOpenPointsChange = (event) => {
        if (event.target.value === "") {
            setOpenPointsInvalid(true)
        } else {
            setOpenPointsInvalid(false)
        }
        setOpenPoints(event.target.value);
    }
    const handleAgreedPointsChange = (event) => {
        if (event.target.value === "") {
            setAgreedPointsInvalid(true)
        } else {
            setAgreedPointsInvalid(false)
        }
        setAgreedPoints(event.target.value);
    }
    const handleNextStepsChange = (event) => {
        if (event.target.value === "") {
            setNextStepsInvalid(true)
        } else {
            setNextStepsInvalid(false)
        }
        setNextSteps(event.target.value);
    }

    const handleRatingChange = (event) => {
        if (event.target.value === "") {
            setRatingInvalid(true)
        } else {
            setRatingInvalid(false)
        }
        setRating(event.target.value);
    };
    const [selectedExternalPersons, setSelectedExternalPersons] = useState([]);
    const handleSelectExternalPersonsChange = (event) => {

        const newSelectedExternalPersons = Array.from(event.detail.value, option => parseInt(option.value));
        newSelectedExternalPersons.length === 0 ? setExternalSelectedPersonsInvalid(true) : setExternalSelectedPersonsInvalid(false)
        setSelectedExternalPersons(newSelectedExternalPersons);

    };

    const roleToText = (role) => {
        const roleMap = {
            'STATUTORY_DEPUTY': 'Statutární zástupce',
            'DELEGATE': 'Zmocněnec',
            'OBSERVER': 'Nahlížející osoba',
            'CONTACT_PERSON': 'Kontaktní osoba'
        };

        return roleMap[role] || 'Role not found';
    }

    return (
        <div>
            <Navigation active={"villages"}/>
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/towns/search">Obce</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            {town ?
                                <a href={`/towns/${townId.length > 6 ? "collective/" : ""}detail/${townId}`}>
                                    {town.name} {townId.length > 6 && "(DSO)"}</a> :
                                <GovSkeleton wcag-label="Loading content of page" variant="secondary" shape="text"
                                             animation="progress" count="1" width="300px"
                                             height="30px"></GovSkeleton>
                            }
                        </li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Založení konzultace</strong></li>
                    </ul>
                </GovBreadcrumbs>
                <div className="gov-container">
                    <div className="gov-container__content">

                        <div className="gov-title--beta">Založení konzultace</div>

                        <div className="form-help-layout gov-tabs">
                            <div className="gov-tabs__content-holder ">
                                <div className="gov-tabs__content is-active" role="tabpanel">
                                    <form method="post">

                                        <h4>{collective ? "Název dobrovolného sdružení obcí" : "Název obce"}</h4>
                                        <p>{town.name}</p>
                                        <h4>{collective ? "Kód dobrovolného sdružení obcí" : "Kód obce"}</h4>
                                        <p>{town.id}</p>
                                        <GovFormControl>
                                            <GovFormInput
                                                size="s" variant="secondary"
                                                input-type="datetime-local"
                                                value={startOfConsultation}
                                                onGov-input={handleStartOfConsultationChange}></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Začátek konzultace*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="datetime-local"
                                                          value={endOfConsultation}
                                                          min={startOfConsultation}
                                                          onGov-input={handleEndOfConsultationChange}></GovFormInput>
                                            <GovFormLabel slot="top" size="s">Konec konzultace*</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormGroup gap="m">
                                                {rcPeople.map((person) => (
                                                    <GovFormCheckbox
                                                        size="m"
                                                        key={person.id}
                                                        value={person.id}
                                                        onGov-change={() => handleTownPersonChange(person.id)}
                                                        checked={townPersonCheckedArray.includes(person.id)}
                                                    >
                                                        <GovFormLabel size="m"
                                                                      slot="label">{person.firstname + " " + person.lastname}</GovFormLabel>
                                                    </GovFormCheckbox>
                                                ))}
                                            </GovFormGroup>
                                            <GovFormLabel slot="top" size="s">Účastníci (Regionální
                                                centrum)*</GovFormLabel>
                                        </GovFormControl>

                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="text" multiline="true"
                                                          placeholder="Uveďte prosím dohodnuté body"
                                                          value={agreedPoints} onGov-input={handleAgreedPointsChange}>
                                            </GovFormInput>
                                            <GovFormLabel slot="top" size="s">Dohodnuté body*</GovFormLabel>
                                        </GovFormControl>


                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="text" multiline="true"
                                                          placeholder="Uveďte prosím otevřené body"
                                                          value={openPoints} onGov-input={handleOpenPointsChange}>
                                            </GovFormInput>
                                            <GovFormLabel slot="top" size="s">Otevřené body</GovFormLabel>
                                        </GovFormControl>
                                        <GovFormControl>
                                            <GovFormInput size="s" variant="secondary"
                                                          input-type="text" multiline="true"
                                                          placeholder="Uveďte prosím další kroky/akční plán"
                                                          value={nextSteps} onGov-input={handleNextStepsChange}>
                                            </GovFormInput>
                                            <GovFormLabel slot="top" size="s">Další kroky/akční plán</GovFormLabel>
                                        </GovFormControl>
                                        {/*<GovFormControl>
                                        <GovFormInput size="s" variant="secondary"
                                                      input-type="text" multiline="true"
                                                      placeholder="Uveďte prosím akční plán"
                                                      value={openPoints} onGov-input={handleOpenPointsChange}>
                                        </GovFormInput>
                                        <GovFormLabel slot="top" size="s">Akční plán*</GovFormLabel>
                                    </GovFormControl>
*/}
                                        <GovFormLabel className="mb-20" slot="top" size="xs">Další
                                            účastníci*</GovFormLabel>
                                        <GovFormMultiSelect size="m" onGov-change={handleSelectExternalPersonsChange}>
                                            {externalPersons.length > 0 &&
                                                <div>
                                                    {externalPersons.map((person) => (
                                                        <option key={person.id}
                                                                value={person.id}>{person.firstname + " " + person.lastname + " " + "(" + person.email + ")"}</option>
                                                    ))
                                                    }
                                                </div>
                                            }
                                        </GovFormMultiSelect>
                                        {/*{rcPeople.length > 0 &&*/}
                                        {/*    <div>*/}
                                        {/*        {rcPeople.map((person) => (*/}
                                        {/*            <div className="gov-layout gov-layout--ratio-1-2">*/}
                                        {/*                <div className="gov-layout__col gov-layout__col--pos-left">*/}
                                        {/*                    <p><b>Jméno a*/}
                                        {/*                        příjmení:</b> {person.name + " " + person.surname}*/}
                                        {/*                    </p>*/}
                                        {/*                    <p><b>Telefon:</b> {person.telephone}</p>*/}
                                        {/*                    <p><b>E-mail:</b> {person.email}</p>  <p>*/}
                                        {/*                    <b>Organizace:</b> {person.organization}</p>*/}
                                        {/*                    <p><b>Role:</b> {roleToText(person.role)}</p></div>*/}
                                        {/*                <div*/}
                                        {/*                    className="gov-layout__col gov-layout__col--pos-right gov-layout__col--spread-1">*/}
                                        {/*                    <GovButton variant="primary" size="m" type="solid"*/}
                                        {/*                               wcag-label="Open dialog box for more information"*/}
                                        {/*                               onGov-click={() => deletePerson(person.id)}>Odebrat*/}
                                        {/*                    </GovButton>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        ))}*/}
                                        {/*        <hr/>*/}
                                        {/*    </div>*/}
                                        {/*}*/}

                                        <GovButton variant="primary" size="s" type="link"
                                                   wcag-label="Open dialog box for more information"
                                                   onGov-click={openModal}>+ Přidat další osobu
                                        </GovButton>
                                        <GovModal open={modalOpen} onGov-close={closeModal}
                                                  label="Přidání dalšího účastníka"
                                                  wcag-close-label="Close dialog box with more information">
                                            <GovFormControl>
                                                <GovFormInput value={personName} onGov-input={handlePersonNameChange}
                                                              size="s" variant="secondary"
                                                              input-type="text"
                                                              placeholder="Jméno"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Jméno*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={personSurname}
                                                              onGov-input={handlePersonSurnameChange}
                                                              size="s" variant="secondary"
                                                              input-type="text"
                                                              placeholder="Příjmení"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Příjmení*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={personTelephone}
                                                              onGov-input={handlePersonTelephoneChange}
                                                              size="s" variant="secondary"
                                                              input-type="text" placeholder="Telefon"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Telefon*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={personEmail} onGov-input={handlePersonEmailChange}
                                                              size="s" variant="secondary"
                                                              input-type="text" placeholder="E-mail"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">E-mail*</GovFormLabel>
                                            </GovFormControl>
                                            <GovFormControl>
                                                <GovFormInput value={personOrganization}
                                                              onGov-input={handlePersonOrganizationChange}
                                                              size="s" variant="secondary"
                                                              input-type="text" placeholder="Organizace"></GovFormInput>
                                                <GovFormLabel slot="top" size="s">Organizace*</GovFormLabel>
                                            </GovFormControl>
                                            <div className="sfpi-title-section mt-20">Role
                                            </div>
                                            <GovFormControl>
                                                <GovFormGroup gap="s">
                                                    <GovFormRadio checked={personRole === "STATUTORY_DEPUTY"}
                                                                  onGov-change={handlePersonRoleChange}
                                                                  value="STATUTORY_DEPUTY" size="s"
                                                                  name="list-radio">
                                                        <GovFormLabel size="s" slot="label">Statutární
                                                            zástupce</GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={personRole === "DELEGATE"}
                                                                  onGov-change={handlePersonRoleChange} value="DELEGATE"
                                                                  size="s"
                                                                  name="list-radio">
                                                        <GovFormLabel size="s" slot="label">Zmocněnec</GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={personRole === "OBSERVER"}
                                                                  onGov-change={handlePersonRoleChange}
                                                                  value="OBSERVER" size="s" name="list-radio">
                                                        <GovFormLabel size="s" slot="label">Nahlížející
                                                            osoba</GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={personRole === "CONTACT_PERSON"}
                                                                  onGov-change={handlePersonRoleChange}
                                                                  value="CONTACT_PERSON"
                                                                  size="s" name="list-radio">
                                                        <GovFormLabel size="s" slot="label">Kontaktní
                                                            osoba</GovFormLabel>
                                                    </GovFormRadio>
                                                    <GovFormRadio checked={personRole === "OTHER"}
                                                                  onGov-change={handlePersonRoleChange}
                                                                  value="OTHER"
                                                                  size="s" name="list-radio">
                                                        <GovFormLabel size="s" slot="label">Jiná
                                                        </GovFormLabel>
                                                    </GovFormRadio>

                                                </GovFormGroup>
                                                <div>
                                                    {personRole === "OTHER" &&
                                                        <div>
                                                            <GovFormControl>
                                                                <GovFormInput value={personRoleDescription}
                                                                              className="mt-10"
                                                                              onGov-input={handlePersonRoleDescriptionChange}
                                                                              size="s" variant="secondary"
                                                                              input-type="text"
                                                                              placeholder="Popis role"></GovFormInput>
                                                            </GovFormControl>
                                                        </div>
                                                    }
                                                </div>
                                            </GovFormControl>
                                            <div>
                                                {phoneInvalid &&
                                                    <GovMessage variant="error">
                                                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                                        <p>Telefon musí obsahovat 9 číslic bez předvolby</p>
                                                    </GovMessage>
                                                }
                                            </div>
                                            <div>
                                                {!newPersonValid &&
                                                    <GovMessage variant="error">
                                                        <GovIcon type="basic" name="info-circle" slot="icon"></GovIcon>
                                                        <p>Nejsou vyplněny všechny povinné položky</p>
                                                    </GovMessage>}
                                            </div>
                                            <GovButton variant="primary" size="m" type="solid"
                                                       wcag-label="Open dialog box for more information"
                                                       onGov-click={addPerson} disabled={!newPersonValid}>Přidat
                                            </GovButton>

                                        </GovModal>


                                    </form>
                                    <GovFormControl>
                                        <div className="sfpi-title-section mt-20">Výsledek konzultace*
                                        </div>
                                        <GovFormGroup gap="s">
                                        <GovFormRadio onGov-change={handleRadioChange}
                                                          value="AGREEMENT" size="s"
                                                          name="list-radio">
                                                <GovFormLabel size="s" slot="label">Dohoda o spolupráci obce s
                                                    RC</GovFormLabel>
                                            </GovFormRadio>
                                            <GovFormRadio onGov-change={handleRadioChange}
                                                          value="NEXT_CONSULTATION_NEEDED" size="s"
                                                          name="list-radio">
                                                <GovFormLabel size="s" slot="label">Je nutná další
                                                    konzultace</GovFormLabel>
                                            </GovFormRadio>
                                            <GovFormRadio onGov-change={handleRadioChange}
                                                          value="NOT_INTERESTED" size="s"
                                                          name="list-radio">
                                                <GovFormLabel size="s" slot="label">Obec nemá zájem o
                                                    spolupráci</GovFormLabel>
                                            </GovFormRadio>
                                            <GovFormRadio onGov-change={handleRadioChange} value="OTHER"
                                                          size="s" name="list-radio">
                                                <GovFormLabel size="s" slot="label">Jiný výsledek</GovFormLabel>
                                            </GovFormRadio>

                                        </GovFormGroup>
                                    </GovFormControl>
                                    <GovFormControl>
                                        <GovFormSelect size="m" variant="secondary"
                                                       value={rating} onGov-change={handleRatingChange}>
                                            <option disabled="disabled" value="">Uveďte prosím hodnocení konzultace
                                                (jako ve škole)
                                            </option>
                                            <option value="1">1 - vynikající</option>
                                            <option value="2">2 - chvalitebná</option>
                                            <option value="3">3 - dobrá</option>
                                            <option value="4">4 - dostatečná</option>
                                            <option value="5">5 - nedostatečná</option>
                                        </GovFormSelect>
                                        <GovFormLabel slot="top" size="m">Hodnocení konzultace*</GovFormLabel>
                                    </GovFormControl>
                                    {!consultationValid &&
                                        <GovMessage variant="error"><p>Nejsou vyplněny všechny povinné položky nebo se
                                            začátek konzultace odehrál po konci</p></GovMessage>}
                                    <GovButton wcag-label="What exactly happens when you click?" variant="primary"
                                               type="solid" size="m" onGov-click={addPreProjectConsultation}
                                               disabled={!consultationValid}>Odeslat
                                    </GovButton>

                                </div>
                                <div className="form-help-column gov-tabs__content " role="tabpanel">

                                    <h4>Nápověda</h4>

                                    <GovAccordionItem variant="primary" size="xs">
                                        <p><b>Statutární zástupce</b> - Právoplatný
                                            statutární zástupce obce. V
                                            portále můžee vystupovat jménem
                                            obce v plném rozsahu a může
                                            delegovat další role.</p>
                                        <p><b>Zmocněnec</b> - Tato osoba bude
                                            mít oprávnění spravovat projekty
                                            Dostupného bydlení v tomto
                                            portále v plném rozsahu, tzn.
                                            stejně jako Statutární Zástupce
                                            (vč. jmenováno dalších Zmocněnců).
                                        </p>
                                        <p><b>Nahlížející osoba</b> - Tato osoba
                                            má oproti Statutárnímu zástupci
                                            nebo Zmocněnci právo pouze
                                            nahlížet na všechna data v
                                            projektu. Nemůže dělat žádné
                                            aktivní operace, ale může být
                                            Kontaktní osobou.</p>
                                        <p><b>Kontaktní osoba</b> - Tato osoba
                                            bude primárním kontaktem pro
                                            jednání s Regionálním centrem.
                                            Může mít jakoukoliv roli (Statutární zástupce, Zmocněnec, nebo Nahlížející
                                            osoba).</p>

                                        <p slot="label">Role zainteresovaných osob</p>
                                    </GovAccordionItem>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
