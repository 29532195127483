import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {CarPageContent} from "../components/contentComponents/CarPageContent";
import {useParams} from "react-router-dom";

export function CarPage() {
    const { id } = useParams();
    return (
        <div>
            <Header active={"settings-active"}></Header>
            <CarPageContent carId={id}></CarPageContent>
            <Footer></Footer>
        </div>
    )
}
