import {Header} from "../components/Header";
import {LoginContent} from "../components/contentComponents/LoginContent";
import {Footer} from "../components/Footer";
import {FirstLoginContent} from "../components/contentComponents/FirstLoginContent";

export function FirstLogin() {
    return (
        <div>
            <Header></Header>
            <FirstLoginContent></FirstLoginContent>
            <Footer></Footer>
        </div>
    )
}