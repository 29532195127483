import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovButton,
    GovFormControl,
    GovFormGroup, GovFormInput,
    GovFormLabel, GovFormMessage, GovFormRadio, GovFormSelect,
    GovIcon, GovSpacer
} from "@gov-design-system-ce/react";
import {useState} from "react";
import '../css/add-new-worker.css';

export function AddNewWorkerContent({edit}) {
    const user = {
        role: "RC_LEADER" // or false based on the user's rights
        // Add other user attributes as needed
    };

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [userId, setUserId] = useState('')
    const [regionalCenter, setRegionalCenter] = useState('')
    const [role, setRole] = useState('')

    const [nameInvalid, setNameInvalid] = useState(false)
    const [surnameInvalid, setSurnameInvalid] = useState(false)
    const [phoneInvalid, setPhoneInvalid] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [userIdInvalid, setUserIdInvalid] = useState(false)
    const [regionalCenterInvalid, setRegionalCenterInvalid] = useState(false)
    const [roleInvalid, setRoleInvalid] = useState(false)

    function sendForm() {
        let readyToSend = checkErrors()
        if (readyToSend) {
            console.log("SEND")
            console.log(process.env.REACT_APP_API_URL);        }
        registerEmployee()
    }

    function registerEmployee(){
        fetch(process.env.REACT_APP_API_URL, {
            method: 'POST', // Specify the method
            headers: {
                'Content-Type': 'application/json',
                // Include other headers here if needed
            },
            body: JSON.stringify({
                firstname: name,
                lastname: surname,
                phone: phone,
                email: email,
                employeeId: userId,
                regionalCentreId: regionalCenter,
                role: role
            }),
        })
            .then(response => response.json()) // Convert the response to JSON
            .then(data => {
                console.log(data); // Handle the response data
            })
            .catch(error => {
                console.error('Error:', error); // Handle any errors
            });
    }

    function isValidEmail(email) {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    function checkErrors() {
        let ready = true
        if (!isValidEmail(email)) {
            ready = false
            setEmailInvalid(true)
        } else {
            setEmailInvalid(false)
        }
        if (!/^\d{9}$/.test(phone)) {
            ready = false
            setPhoneInvalid(true)
        } else {
            setPhoneInvalid(false)
        }
        if (name === "") {
            ready = false
            setNameInvalid(true)
        } else {
            setNameInvalid(false)
        }
        if (surname === "") {
            ready = false
            setSurnameInvalid(true)
        } else {
            setSurnameInvalid(false)
        }
        if (!/^\d+$/.test(userId)) {
            ready = false
            setUserIdInvalid(true)
        } else {
            setUserIdInvalid(false)
        }
        if (regionalCenter === "") {
            ready = false
            setRegionalCenterInvalid(true)
        } else {
            setRegionalCenterInvalid(false)
        }
        if (role === "") {
            ready = false
            setRoleInvalid(true)
        } else {
            setRoleInvalid(false)
        }
        return ready
    }

    const handleRadioChange = (event) => {

        if (event.target.value === "Specialista RC"){
            setRole("RC_EMPLOYEE")
        }
        else if (event.target.value === "Manažer RC"){
            setRole("RC_LEADER")
        }
    }

    const handleChangeName = (event) => {
        setName(event.target.value)
    }
    const handleChangeSurname = (event) => {
        setSurname(event.target.value)
    }
    const handleChangePhone = (event) => {
        const phoneNumber = event.target.value;
        setPhone(phoneNumber);
    }
    const handleChangeUserID = (event) => {
        const userID = event.target.value;
        setUserId(userID);
    }
    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
    }

    const handleRegionalCenterChange = (event) => {
        setRegionalCenter(event.target.value);
        console.log(event.target.value)
    };


    return (
        <div>
            <Navigation active={"user-management"} />
            <div className="gov-container">
                <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                    <ul>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Úvod</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <a href="/">Správa uživatelů</a></li>
                        <li>
                            <GovIcon type="basic" name="chevron-right"></GovIcon>
                            <strong>Přidání/úprava uživatele</strong>
                        </li>
                    </ul>
                </GovBreadcrumbs>
                <GovSpacer size="s"></GovSpacer>
                <div className="gov-title--beta">Přidání/úprava pracovníka regionálního centra</div>
                <form className="gov-form">
                    <GovFormGroup>
                        <GovFormControl>
                            <GovFormInput invalid={nameInvalid} onGov-input={handleChangeName} value={name} size="s"
                                          variant="secondary" input-type="text"
                                          placeholder="Jméno"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Jméno*</GovFormLabel>
                            <div>
                                {nameInvalid && <GovFormMessage slot="bottom" variant="error">Toto pole je povinné
                                    <GovIcon type="basic" name="exclamation-triangle-fill" slot="icon"></GovIcon>
                                </GovFormMessage>}
                            </div>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormInput invalid={surnameInvalid} onGov-input={handleChangeSurname}
                                          value={surname} size="s"
                                          variant="secondary" input-type="text"
                                          placeholder="Příjmení"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Příjmení*</GovFormLabel>
                            <div>
                                {surnameInvalid &&
                                    <GovFormMessage slot="bottom" variant="error">Toto pole je povinné
                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                 slot="icon"></GovIcon>
                                    </GovFormMessage>}
                            </div>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormInput invalid={emailInvalid} onGov-input={handleChangeEmail}
                                          value={email}
                                          size="s" variant="secondary" input-type="email"
                                          placeholder="Email"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Email*</GovFormLabel>
                            <div>
                                {emailInvalid &&
                                    <GovFormMessage slot="bottom" variant="error">Email není ve správném
                                        formátu
                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                 slot="icon"></GovIcon>
                                    </GovFormMessage>}
                            </div>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormInput invalid={phoneInvalid} onGov-input={handleChangePhone}
                                          value={phone}
                                          size="s" variant="secondary" input-type="tel"
                                          placeholder="Telefonní číslo"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Telefonní číslo*</GovFormLabel>
                            <div>
                                {phoneInvalid &&
                                    <GovFormMessage slot="bottom" variant="error">Telefon není ve správném
                                        formátu
                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                 slot="icon"></GovIcon>
                                    </GovFormMessage>}
                            </div>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormInput invalid={userIdInvalid} onGov-input={handleChangeUserID}
                                          value={userId}
                                          size="s" variant="secondary" input-type="number"
                                          placeholder="Zaměstnanecké ID"></GovFormInput>
                            <GovFormLabel slot="top" size="s">Zaměstnanecké ID*</GovFormLabel>
                            <div>
                                {userIdInvalid &&
                                    <GovFormMessage slot="bottom" variant="error">Zaměstnanecké ID není ve správném
                                        formátu
                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                 slot="icon"></GovIcon>
                                    </GovFormMessage>}
                            </div>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormSelect size="m" variant="secondary" value={regionalCenter} onGov-change={handleRegionalCenterChange}>
                                <option disabled="disabled"  value="">Regionální centrum</option>
                                <option value="1">Moravskoslezský kraj</option>
                                <option value="2">Středočeský kraj a Praha</option>
                                <option value="3">Ústecký a Karlovarský kraj</option>
                                <option value="4">Královehradecký a Liberecký kraj</option>
                                <option value="5">Pardubický kraj a kraj Vysočina</option>
                                <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                <option value="7">Zlínský a Jihomoravský kraj</option>
                                <option value="8">Olomoucký kraj</option>
                            </GovFormSelect>
                            <GovFormLabel slot="top" size="s">Vyberte regionální centrum*</GovFormLabel>
                            <div>
                                {regionalCenterInvalid &&
                                    <GovFormMessage slot="bottom" variant="error">Toto pole je povinné
                                        <GovIcon type="basic" name="exclamation-triangle-fill"
                                                 slot="icon"></GovIcon>
                                    </GovFormMessage>}
                            </div>
                        </GovFormControl>
                        <GovFormControl>
                            <GovFormLabel slot="top" size="s">Role*</GovFormLabel>
                            <GovSpacer size="s"></GovSpacer>
                            <GovFormGroup gap="s">
                                <GovFormRadio onGov-change={handleRadioChange} value="Manažer RC"
                                              size="s" name="list-radio">
                                    <GovFormLabel size="s" slot="label">Manažer RC</GovFormLabel>
                                </GovFormRadio>
                                <GovFormRadio onGov-change={handleRadioChange} value="Specialista RC" size="s"
                                              name="list-radio">
                                    <GovFormLabel size="s" slot="label">Specialista RC</GovFormLabel>
                                </GovFormRadio>
                                <div>
                                    {roleInvalid &&
                                        <GovFormMessage slot="bottom" variant="error">Toto pole je povinné
                                            <GovIcon type="basic" name="exclamation-triangle-fill"
                                                     slot="icon"></GovIcon>
                                        </GovFormMessage>}
                                </div>
                            </GovFormGroup>
                        </GovFormControl>
                    </GovFormGroup>
                    <GovButton className={"send-button"} onGov-click={sendForm}
                               wcag-label="What exactly happens when you click?"
                               variant="primary" type="solid" size="m">Uložit
                    </GovButton>
                    {edit &&
                        <GovButton wcag-label="What exactly happens when you click?" variant="primary" type="link"
                                   size="m">Odebrat uživatele
                        </GovButton>
                    }
                </form>
            </div>
        </div>
    )
}
