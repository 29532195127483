import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {SettingsContent} from "../components/contentComponents/SettingsContent";

export function Settings() {
    return (
        <div>
            <Header active={"settings-active"}></Header>
            <SettingsContent></SettingsContent>
            <Footer></Footer>
        </div>
    )
}
