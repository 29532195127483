import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {CarsPageContent} from "../components/contentComponents/CarsPageContent";

export function CarsPage() {
    return (
        <div>
            <Header active={"settings-active"}></Header>
            <CarsPageContent></CarsPageContent>
            <Footer></Footer>
        </div>
    )
}
