import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {useParams} from "react-router-dom";
import {UserMonthReportsContent} from "../components/contentComponents/UserMonthReportsContent";

export function UserMonthReports() {
    const { userId } = useParams();
    return (
        <div>
            <Header></Header>
            <UserMonthReportsContent userId ={userId}></UserMonthReportsContent>
            <Footer></Footer>
        </div>
    )
}
