import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {ConsultationDetailContent} from "../components/contentComponents/ConsultationDetailContent";


export function ConsultationDetail() {
    return (
        <div>
            <Header></Header>
            <ConsultationDetailContent></ConsultationDetailContent>
            <Footer></Footer>
        </div>
    )
}
