import Navigation from "../Navigation";
import {
    GovBreadcrumbs,
    GovButton,
    GovFormControl, GovFormSelect,
    GovIcon,
    GovModal,
    GovTile,
    GovTiles, GovToast
} from "@gov-design-system-ce/react";
import {fuelTypeToText, parseJwt} from "../utils/utils";
import {useState} from "react";

export function SettingsContent() {
    const name = localStorage.getItem("firstname") + " " + localStorage.getItem("lastname")
    const jwt = localStorage.getItem("jwt");
    const authority = parseJwt(localStorage.getItem("jwt")).role[0].authority;
    const RCid = localStorage.getItem("RCid");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const [chosenRegionalCenter, setChosenRegionalCenter] = useState(authority === "HEADQUARTERS" ? "0" : RCid);

    const [requestSuccess, setRequestSuccess] = useState(false);

    const handleSelectRegionalCenterChange = (event) => {
        if (event.target.value !== "0") {
            setChosenRegionalCenter(event.target.value);
        } else {
            setChosenRegionalCenter("");
        }
    };

    function generateExport() {
        fetch(process.env.REACT_APP_API_URL + `/report/rc/${chosenRegionalCenter}/request`, {
            headers: {
                'Authorization': `Bearer ${jwt}`
            },
        })
            .then(data => {
                setRequestSuccess(true)
                setChosenRegionalCenter(authority === "HEADQUARTERS" ? "0" : RCid)
                }
            )
            .catch(error => console.error('Error:', error));
    }

    return (
        <div className="vh-100">
            <Navigation/>
            <div className="gov-container">
                <div className="gov-container__content">
                    <GovBreadcrumbs className="gov-breadcrumbs-login" wcag-label="You are in the following level">
                        <ul>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <a href="/">Úvod</a></li>
                            <li>
                                <GovIcon type="basic" name="chevron-right"></GovIcon>
                                <strong>Nastavení</strong>
                            </li>
                        </ul>
                    </GovBreadcrumbs>
                </div>
                <div className="gov-container">
                    <GovTiles columns="2">
                        <GovTile href="/profile/edit"><p>Navštívit formulář pro změnu osobních údajů nebo přidání
                            řidičského průkazu.</p><h3
                            slot="title">Změna osobních údajů</h3>
                            <GovIcon type="basic" name="pencil-square" slot="icon"></GovIcon>
                        </GovTile>
                        <GovTile href="/profile/cars"><p>Navštívit stránku pro správu vlastního vozového parku.</p><h3
                            slot="title">Správa vlastního vozového parku</h3>
                            <GovIcon type="basic" name="car" slot="icon"></GovIcon>
                        </GovTile>
                        {(authority === "RC_LEADER" || authority === "HEADQUARTERS") &&
                            <GovTile href="/settings/cars/sfpi"><p>Navštívit stránku pro správu vozového parku SFPI.</p>
                                <h3
                                    slot="title">Správa vozového parku SFPI</h3>
                                <GovIcon type="complex" name="car" slot="icon"></GovIcon>
                            </GovTile>
                        }
                        {(authority === "RC_LEADER" || authority === "HEADQUARTERS") &&
                            <GovTile href="/settings/cars/users"><p>Navštívit stránku pro správu vlastního vozového
                                parku všech uživatelů.</p><h3
                                slot="title">Správa vozového parku uživatelů</h3>
                                <GovIcon type="basic" name="gear" slot="icon"></GovIcon>
                            </GovTile>
                        }
                        {(authority === "RC_LEADER" || authority === "HEADQUARTERS") &&
                            <GovTile href="#" onClick={() => openModal()}>
                                <p>Vyžádat export dat za regionální centrum.</p>
                                <h3 slot="title">Vyžádat export dat</h3>
                                <GovIcon type="basic" name="download" slot="icon"></GovIcon>
                            </GovTile>
                        }
                    </GovTiles>
                </div>
                <GovModal open={isModalOpen} onGov-close={closeModal} label="Vyžádat export dat"
                          wcag-close-label="Close dialog box with more information">
                    <div>

                        <div>
                            {authority === "HEADQUARTERS" &&
                                <div>
                                    <GovFormControl>
                                        <GovFormSelect size="m" variant="secondary"
                                                       value={chosenRegionalCenter}
                                                       onGov-change={handleSelectRegionalCenterChange}>
                                            <option disabled="disabled" value="">Vyberte regionální centrum</option>
                                            <option value="0">Všechna regionální centra</option>
                                            <option value="1">Moravskoslezský kraj</option>
                                            <option value="2">Středočeský kraj a Praha</option>
                                            <option value="3">Ústecký a Karlovarský kraj</option>
                                            <option value="4">Královehradecký a Liberecký kraj</option>
                                            <option value="5">Pardubický kraj a kraj Vysočina</option>
                                            <option value="6">Jihočeský kraj a Plzeňský kraj</option>
                                            <option value="7">Zlínský a Jihomoravský kraj</option>
                                            <option value="8">Olomoucký kraj</option>
                                        </GovFormSelect>
                                    </GovFormControl>
                                </div>
                            }
                            {authority === "RC_LEADER" &&
                            <div>
                                <p>Budou exportována data regionálního centra: {localStorage.getItem("RCname")}.</p>
                            </div>
                            }
                            <GovButton onGov-click={generateExport} wcag-label="What exactly happens when you click?"
                                       variant="primary" type="solid"
                                       class="mt-10"
                                       disabled={chosenRegionalCenter === ""}
                                       size="m">Vyžádat export dat
                            </GovButton>
                        </div>
                        {requestSuccess &&
                            <div>
                            <GovToast variant="success" type="classic" gravity="bottom" position="center" time="0"
                                      wcag-close-label="Close message"
                                        onGov-close={() => setRequestSuccess(false)}>Žádost o export dat byla úspěšně provedena.
                                Soubor s exportem dat Vám bude zaslán emailem.
                                <GovIcon type="basic" name="check-lg" slot="icon"></GovIcon>
                            </GovToast>
                            </div>
                        }
                    </div>
                </GovModal>
            </div>
        </div>
    )
}
