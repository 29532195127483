import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {TimesheetContent} from "../components/contentComponents/TimesheetContent";
import {useParams} from "react-router-dom";
import {TimesheetOneMonthContent} from "../components/contentComponents/TimesheetOneMonthContent";

export function TimesheetOneMonth() {
    const { monthReportId } = useParams();
    return (
        <div>
            <Header></Header>
            <TimesheetOneMonthContent monthReportId={monthReportId} ></TimesheetOneMonthContent>
            <Footer></Footer>
        </div>
    )
}
