import {Footer} from "../components/Footer";
import {Header} from "../components/Header";
import {NewProjectContent} from "../components/contentComponents/NewProjectContent";

export function NewProject() {
    return (
        <div>
            <Header></Header>
            <NewProjectContent></NewProjectContent>
            <Footer></Footer>
        </div>
    )
}
