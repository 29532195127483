import {Header} from "../components/Header";
import {Footer} from "../components/Footer";
import {TimesheetContent} from "../components/contentComponents/TimesheetContent";
import {useParams} from "react-router-dom";

export function Timesheet() {
    const { userId } = useParams();
    return (
        <div>
            <Header></Header>
            <TimesheetContent userId ={userId}></TimesheetContent>
            <Footer></Footer>
        </div>
    )
}
